import React, { useEffect, useState } from "react";
import axios from "../../axios";
import AccountsButtonClickTable from "../Tables/AccountsButtonClickTable";

const browsingUsersHeaders = [
  {
    key: "button_name",
    label: "Button Name",
  },
  {
    key: "total_click_count",
    label: "Total click count",
  },
  {
    key: "24hrs_click_count",
    label: "Today's click count",
  },
  {
    key: "7days_click_count",
    label: "Last 7 days click count",
  },
  {
    key: "last_30_days_click_count",
    label: "Last 30 days click count",
  },
  {
    key: "last_1year_click_count",
    label: "Last 1 year click count",
  },
];

function BrowsingUsers() {
  const [browsingUsers, setBrowsingUsers] = useState();
  console.log(browsingUsers)

  useEffect(() => {
    function mergeData(data) {
      const merged = {};

      function addData(period, section, buttons) {
        if (!merged[section]) {
          merged[section] = {};
        }
        buttons.forEach((button) => {
          if (!merged[section][button.button_name]) {
            merged[section][button.button_name] = {
              button_name: button.button_name,
              total: 0,
              last_7_days: 0,
              last_30_days: 0,
            };
          }
          merged[section][button.button_name][period] = button.click_count;
        });
      }

      Object.keys(data).forEach((period) => {
        Object.keys(data[period]).forEach((section) => {
          addData(period, section, data[period][section]);
        });
      });

      // Convert merged object to the desired format
      const result = {};
      Object.keys(merged).forEach((section) => {
        result[section] = Object.values(merged[section]);
      });

      return result;
    }
    const fetchReceipts = async () => {
      axios
        .get("/button-click-summary-2/")
        .then((res) => {
          console.log(res);
          setBrowsingUsers(mergeData(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
      // axios
      //   .get("/button-click-summary/")
      //   .then((res) => {
      //     console.log(res);
      //     setBrowsingUsers(res.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    };
    fetchReceipts();
  }, []);

  return (
    <div className="pb-[2rem]">
      <div className="lg:px-[2rem] px-[1rem]">
        {/* <div className="p-[0.62rem] rounded-[0.75rem] bg-[#0FB23C1A] font-poppins mt-[2rem]">
          <h1 className="flex items-center gap-[0.62rem] font-semibold text-[0.875rem] text-[#018B28]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_1652_8242)">
                <path
                  d="M8.6665 5.00016C8.6665 5.17697 8.59627 5.34654 8.47124 5.47157C8.34622 5.59659 8.17665 5.66683 7.99984 5.66683C7.82303 5.66683 7.65346 5.59659 7.52843 5.47157C7.40341 5.34654 7.33317 5.17697 7.33317 5.00016C7.33317 4.82335 7.40341 4.65378 7.52843 4.52876C7.65346 4.40373 7.82303 4.3335 7.99984 4.3335C8.17665 4.3335 8.34622 4.40373 8.47124 4.52876C8.59627 4.65378 8.6665 4.82335 8.6665 5.00016ZM6.6665 7.50016C6.6665 7.36755 6.71918 7.24038 6.81295 7.14661C6.90672 7.05284 7.0339 7.00016 7.1665 7.00016H8.1665C8.29911 7.00016 8.42629 7.05284 8.52006 7.14661C8.61383 7.24038 8.6665 7.36755 8.6665 7.50016V10.3335H9.1665C9.29911 10.3335 9.42629 10.3862 9.52006 10.4799C9.61383 10.5737 9.6665 10.7009 9.6665 10.8335C9.6665 10.9661 9.61383 11.0933 9.52006 11.187C9.42629 11.2808 9.29911 11.3335 9.1665 11.3335H7.1665C7.0339 11.3335 6.90672 11.2808 6.81295 11.187C6.71918 11.0933 6.6665 10.9661 6.6665 10.8335C6.6665 10.7009 6.71918 10.5737 6.81295 10.4799C6.90672 10.3862 7.0339 10.3335 7.1665 10.3335H7.6665V8.00016H7.1665C7.0339 8.00016 6.90672 7.94748 6.81295 7.85372C6.71918 7.75995 6.6665 7.63277 6.6665 7.50016Z"
                  fill="#018B28"
                />
                <path
                  d="M7.99984 0.666504C12.0498 0.666504 15.3332 3.94984 15.3332 7.99984C15.3332 12.0498 12.0498 15.3332 7.99984 15.3332C3.94984 15.3332 0.666504 12.0498 0.666504 7.99984C0.666504 3.94984 3.94984 0.666504 7.99984 0.666504ZM1.6665 7.99984C1.6665 9.67954 2.33376 11.2905 3.52149 12.4782C4.70922 13.6659 6.32013 14.3332 7.99984 14.3332C9.67954 14.3332 11.2905 13.6659 12.4782 12.4782C13.6659 11.2905 14.3332 9.67954 14.3332 7.99984C14.3332 6.32013 13.6659 4.70922 12.4782 3.52149C11.2905 2.33376 9.67954 1.6665 7.99984 1.6665C6.32013 1.6665 4.70922 2.33376 3.52149 3.52149C2.33376 4.70922 1.6665 6.32013 1.6665 7.99984Z"
                  fill="#018B28"
                />
              </g>
              <defs>
                <clipPath id="clip0_1652_8242">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            INSTRUCTIONS
          </h1>
          <p className="text-[0.875rem] text-[#161616] mt-[0.62rem]">
            To sort any of the tabs click on the tab heading, and click again to
            reverse the data. Select a user by clicking on the User Agent to
            view affected user.
          </p>
        </div> */}
        <AccountsButtonClickTable
          page={"Home"}
          head={browsingUsersHeaders}
          body={browsingUsers?.home}
        />
        <AccountsButtonClickTable
          page={"Markets"}
          head={browsingUsersHeaders}
          body={browsingUsers?.markets}
        />
        <AccountsButtonClickTable
          page={"Holdings"}
          head={browsingUsersHeaders}
          body={browsingUsers?.holdings}
        />
        <AccountsButtonClickTable
          page={"Wallet"}
          head={browsingUsersHeaders}
          body={browsingUsers?.wallet}
        />
        <AccountsButtonClickTable
          page={"Alert"}
          head={browsingUsersHeaders}
          body={browsingUsers?.alert}
        />
        <AccountsButtonClickTable
          page={"Account"}
          head={browsingUsersHeaders}
          body={browsingUsers?.accounts}
        />
      </div>
    </div>
  );
}

export default BrowsingUsers;
