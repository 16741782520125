import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import { useLocation, useNavigate } from "react-router-dom";

function TopMenu({ route }) {
  const [isOpen, setIsOpen] = useState(false);
  const params = useLocation();
  const navigate = useNavigate();
  // const userId = params.search.split("=")[1]
  const userIdAvailable = params.search.split("=")[0];
  const userIdAvailables = params.search.split("=")[1];
  console.log(userIdAvailables);
  const isAnalyst = localStorage.getItem("is_analyst")
  return (
    <div className="lg:sticky top-0  z-50 lg:border-b- lg:border-b-[#808080] bg-white flex items-center justify-between lg:px-[2rem] px-[1rem] py-[0.5rem]">
      {!userIdAvailable && (
        <h1 className="lg:text-[1.5rem] text-[1.25rem] font-medium font-poppins text-[#161616]">
          {route === "analytics"
            ? "Analytics"
            : route === "users"
            ? "User Management"
            : route === "trades"
            ? "Trades Execution Management"
            : route === "receipts"
            ? "Payment Receipts Management"
            : route === "referrals"
            ? "Referral Management"
            : route === "withdrawal"
            ? "Withdrawal Request Management"
            : route === "browsing-users"
            ? "Browsing Users"
            : route === "ideas"
            ? "Ideas"
            : "Admin Log"}
        </h1>
      )}
      {userIdAvailable === "?id" && (
        <div className="flex items-center gap-[0.5rem]">
          <div
            onClick={() => navigate(-1)}
            className="cursor-pointer bg-[#F1F1F1] lg:w-[3.125rem] lg:h-[3.125rem] w-[2.5rem] h-[2.5rem] rounded-full flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3.99994 12L3.29294 12.707L2.58594 12L3.29294 11.293L3.99994 12ZM18.9999 11C19.2652 11 19.5195 11.1054 19.707 11.2929C19.8946 11.4805 19.9999 11.7348 19.9999 12C19.9999 12.2652 19.8946 12.5196 19.707 12.7071C19.5195 12.8947 19.2652 13 18.9999 13V11ZM9.29294 18.707L3.29294 12.707L4.70694 11.293L10.7069 17.293L9.29294 18.707ZM3.29294 11.293L9.29294 5.29303L10.7069 6.70703L4.70694 12.707L3.29294 11.293ZM3.99994 11H18.9999V13H3.99994V11Z"
                fill="black"
              />
            </svg>
          </div>
          <h1 className="lg:text-[1.5rem] text-[1.25rem] font-medium font-poppins text-[#161616]">
            Account Managemant
          </h1>
        </div>
      )}
      {userIdAvailable === "?affilliate" && (
        <div className="flex items-center gap-[0.5rem]">
          <div
            onClick={() => navigate(-1)}
            className="cursor-pointer bg-[#F1F1F1] lg:w-[3.125rem] lg:h-[3.125rem] w-[2.5rem] h-[2.5rem] rounded-full flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3.99994 12L3.29294 12.707L2.58594 12L3.29294 11.293L3.99994 12ZM18.9999 11C19.2652 11 19.5195 11.1054 19.707 11.2929C19.8946 11.4805 19.9999 11.7348 19.9999 12C19.9999 12.2652 19.8946 12.5196 19.707 12.7071C19.5195 12.8947 19.2652 13 18.9999 13V11ZM9.29294 18.707L3.29294 12.707L4.70694 11.293L10.7069 17.293L9.29294 18.707ZM3.29294 11.293L9.29294 5.29303L10.7069 6.70703L4.70694 12.707L3.29294 11.293ZM3.99994 11H18.9999V13H3.99994V11Z"
                fill="black"
              />
            </svg>
          </div>
          <h1 className="lg:text-[1.5rem] text-[1.25rem] font-medium font-poppins text-[#161616]">
            {userIdAvailables === "create"
              ? "Create Affiliate Program"
              : userIdAvailables === "leaderboard"
              ? "Affiliate Program Leaderboard"
              : "Affiliate Program"}
          </h1>
        </div>
      )}
      <div className="flex items-center gap-[1rem]">
        <div className="w-[3.125rem] h-[3.125rem] rounded-full bg-[#F1F1F1] flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 1C11.7133 0.999962 11.43 1.06155 11.1692 1.18059C10.9084 1.29963 10.6763 1.47333 10.4885 1.68994C10.3008 1.90654 10.1617 2.16098 10.0809 2.43601C10.0001 2.71104 9.97929 3.00024 10.02 3.284C8.57063 3.71193 7.29857 4.59722 6.39379 5.80767C5.48902 7.01812 5.00008 8.48877 5 10V18H4C3.73478 18 3.48043 18.1054 3.29289 18.2929C3.10536 18.4804 3 18.7348 3 19C3 19.2652 3.10536 19.5196 3.29289 19.7071C3.48043 19.8946 3.73478 20 4 20H20C20.2652 20 20.5196 19.8946 20.7071 19.7071C20.8946 19.5196 21 19.2652 21 19C21 18.7348 20.8946 18.4804 20.7071 18.2929C20.5196 18.1054 20.2652 18 20 18H19V10C18.9999 8.48877 18.511 7.01812 17.6062 5.80767C16.7014 4.59722 15.4294 3.71193 13.98 3.284C14.0207 3.00024 13.9999 2.71104 13.9191 2.43601C13.8383 2.16098 13.6992 1.90654 13.5115 1.68994C13.3237 1.47333 13.0916 1.29963 12.8308 1.18059C12.57 1.06155 12.2867 0.999962 12 1ZM14 22C14 22.2652 13.8946 22.5196 13.7071 22.7071C13.5196 22.8946 13.2652 23 13 23H11C10.7348 23 10.4804 22.8946 10.2929 22.7071C10.1054 22.5196 10 22.2652 10 22C10 21.7348 10.1054 21.4804 10.2929 21.2929C10.4804 21.1054 10.7348 21 11 21H13C13.2652 21 13.5196 21.1054 13.7071 21.2929C13.8946 21.4804 14 21.7348 14 22Z"
              fill="#161616"
            />
          </svg>
        </div>
        {isAnalyst === "false" && <div
          onClick={() => setIsOpen(!isOpen)}
          className="w-[3.125rem] lg:hidden h-[3.125rem] rounded-full bg-[#F1F1F1] flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 13C4.55228 13 5 12.5523 5 12C5 11.4477 4.55228 11 4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13Z"
              fill="#161616"
            />
            <path
              d="M20.06 11H7.94C7.42085 11 7 11.4209 7 11.94V12.06C7 12.5791 7.42085 13 7.94 13H20.06C20.5791 13 21 12.5791 21 12.06V11.94C21 11.4209 20.5791 11 20.06 11Z"
              fill="#161616"
            />
            <path
              d="M20.06 16H3.94C3.42085 16 3 16.4209 3 16.94V17.06C3 17.5791 3.42085 18 3.94 18H20.06C20.5791 18 21 17.5791 21 17.06V16.94C21 16.4209 20.5791 16 20.06 16Z"
              fill="#161616"
            />
            <path
              d="M20.06 6H3.94C3.42085 6 3 6.42085 3 6.94V7.06C3 7.57915 3.42085 8 3.94 8H20.06C20.5791 8 21 7.57915 21 7.06V6.94C21 6.42085 20.5791 6 20.06 6Z"
              fill="#161616"
            />
          </svg>
        </div>}
      </div>
      <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} route={route} />
    </div>
  );
}

export default TopMenu;
