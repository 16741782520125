import React, { Fragment, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import ReferralManagement from '../Modals/ReferralManagement';
import ConfirmReferralReward from '../Modals/ConfirmReferralReward';
import { Menu, Transition } from '@headlessui/react';
import { CSVLink } from "react-csv";

function ConvertData(params) {
    const date = new Date(params);

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };

    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
}

function ReferralManagementTable({ head, body, refresh, setRefresh }) {
    const [currentItems, setCurrentItems] = useState(null)
    const [pageCount, setPageCount] = useState(0)
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    const [selectedReferral, setSelectedReferral] = useState({
        referralID: "",
        referring_user: "",
        referred_user: "",
        reward: "",
        status: "",
        date: "",
        user_id: "",
        flag: "",
        tethered_requirements:""
    })
    const [selectedFilter, setSelectedFilter] = useState("")
    const [searchFilter, setSearchFilter] = useState("")

    console.log(body)

    const CSVdata = body

    const exportData = (data, fileName, type) => {
        // Create a link and download the file
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    useEffect(() => {
        let newBody
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);

        if (selectedFilter !== "") {
            newBody = body.filter(item => {
                return item.reward.toLowerCase().includes(selectedFilter)
            })
        } else if (searchFilter !== "") {
            newBody = body.filter(item => {
                return item.referring_user.toLowerCase().includes(searchFilter)
            })
        } else {
            newBody = body
        }
        setCurrentItems(newBody.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(newBody.length / itemsPerPage));
    }, [body, itemOffset, searchFilter, selectedFilter])

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % body.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        setCurrentPage(event.selected + 1)
    };
    return (
        <div className='bg-white rounded-[0.75rem] mt-[2rem] p-[1rem] shadow-[0_2px_16px_0_rgba(128,128,128,0.18)]'>
            <div className='flex lg:flex-row flex-col items-center justify-between mb-[1.5rem]'>
                <div className='flex lg:flex-row flex-col items-center gap-[1rem]'>
                    <p className='font-poppins text-[0.875rem] text-[#16161699]'>Page {currentPage} of {pageCount} ({body.length} items)</p>
                    <div className='flex items-center bg-[#F1F1F1] rounded-[0.75rem] px-[1rem] gap-[0.62rem]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21.0002 21.0002L16.6572 16.6572M16.6572 16.6572C17.4001 15.9143 17.9894 15.0324 18.3914 14.0618C18.7935 13.0911 19.0004 12.0508 19.0004 11.0002C19.0004 9.9496 18.7935 8.90929 18.3914 7.93866C17.9894 6.96803 17.4001 6.08609 16.6572 5.34321C15.9143 4.60032 15.0324 4.01103 14.0618 3.60898C13.0911 3.20693 12.0508 3 11.0002 3C9.9496 3 8.90929 3.20693 7.93866 3.60898C6.96803 4.01103 6.08609 4.60032 5.34321 5.34321C3.84288 6.84354 3 8.87842 3 11.0002C3 13.122 3.84288 15.1569 5.34321 16.6572C6.84354 18.1575 8.87842 19.0004 11.0002 19.0004C13.122 19.0004 15.1569 18.1575 16.6572 16.6572Z" stroke="#161616" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <input onChange={e => {
                            setSelectedFilter("")
                            setSearchFilter(e.target.value)
                        }} type="text" placeholder='Search referrals...' className='bg-[#F1F1F1] outline-none rounded-[0.75rem] py-[0.62rem]' />
                    </div>
                </div>
                <div className='flex lg:flex-row flex-col mt-5 lg:mt-0 items-center gap-[1rem]'>
                    <Menu>
                        {({ open }) => (
                            <>
                                <Menu.Button onClick={() => setSelectedFilter("")}>
                                    <button className={`${open ? "border border-[#0FB23C]" : "border border-[#F1F1F1]"} flex items-center gap-[0.25rem] bg-[#F1F1F1] py-[0.5rem] px-[0.75rem] rounded-[0.75rem] font-poppins text-[0.875rem] text-[#161616]`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                            <g clip-path="url(#clip0_1652_8257)">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7998 4.5C3.7998 4.10218 3.95784 3.72064 4.23914 3.43934C4.52045 3.15804 4.90198 3 5.2998 3H20.2998C20.6976 3 21.0792 3.15804 21.3605 3.43934C21.6418 3.72064 21.7998 4.10218 21.7998 4.5V6.586C21.7997 7.11639 21.5889 7.62501 21.2138 8L15.7998 13.414V20.838C15.7998 21.0255 15.7519 21.2099 15.6606 21.3737C15.5693 21.5375 15.4377 21.6753 15.2781 21.7739C15.1186 21.8724 14.9366 21.9286 14.7492 21.9371C14.5619 21.9455 14.3755 21.9059 14.2078 21.822L10.4908 19.964C10.2832 19.8602 10.1086 19.7006 9.98651 19.5031C9.86446 19.3057 9.79981 19.0781 9.7998 18.846V13.414L4.3858 8C4.01071 7.62501 3.79992 7.11639 3.7998 6.586V4.5ZM5.7998 5V6.586L11.3598 12.146C11.4993 12.2853 11.6099 12.4507 11.6854 12.6327C11.7609 12.8148 11.7998 13.0099 11.7998 13.207V18.382L13.7998 19.382V13.207C13.7998 12.809 13.9578 12.427 14.2398 12.147L19.7998 6.585V5H5.7998Z" fill="#161616" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1652_8257">
                                                    <rect width="24" height="24" fill="white" transform="translate(0.799805)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p>Filters</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <g clip-path="url(#clip0_1652_8262)">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.27104 10.4712C9.14602 10.5962 8.97648 10.6664 8.79971 10.6664C8.62293 10.6664 8.45339 10.5962 8.32837 10.4712L4.55704 6.6999C4.49337 6.63841 4.44258 6.56484 4.40764 6.48351C4.3727 6.40217 4.35431 6.31469 4.35354 6.22617C4.35277 6.13765 4.36964 6.04986 4.40316 5.96793C4.43668 5.886 4.48618 5.81157 4.54878 5.74897C4.61137 5.68638 4.68581 5.63688 4.76774 5.60336C4.84967 5.56983 4.93746 5.55297 5.02598 5.55374C5.11449 5.55451 5.20197 5.5729 5.28331 5.60784C5.36465 5.64277 5.43821 5.69356 5.49971 5.75724L8.79971 9.05724L12.0997 5.75724C12.2254 5.6358 12.3938 5.5686 12.5686 5.57012C12.7434 5.57164 12.9106 5.64175 13.0343 5.76536C13.1579 5.88896 13.228 6.05617 13.2295 6.23097C13.231 6.40577 13.1638 6.57417 13.0424 6.6999L9.27104 10.4712Z" fill="#161616" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1652_8262">
                                                    <rect width="16" height="16" fill="white" transform="translate(0.799805)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </button>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute shadow-[0_4px_16px_2px_rgba(1,1,1,0.15)] lg:w-[21.2rem] w-[90%] z-10 lg:mt-[8rem] mt-[3rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] bg-[#F1F1F1] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setSearchFilter("")
                                                        setSelectedFilter("deposit referral commission")
                                                        // console.log("clicked")
                                                    }}
                                                    className={`group gap-[1rem] justify-start flex w-full items-center font-poppins rounded-t-[0.75rem] py-[0.5rem] px-[0.75rem] text-[0.875rem]`}
                                                >
                                                    <input type="radio" checked={selectedFilter === "buy"} className='scale-125 accent-[#0FB23C] acc' />
                                                    <p className='text-[#16161699]'>Reward: <span className='text-[#161616]'>Deposit Referral Commission</span></p>
                                                </button>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setSearchFilter("")
                                                        setSelectedFilter("10% brokerage commission")
                                                        // console.log("clicked")
                                                    }}
                                                    className={`group gap-[1rem] justify-start flex w-full items-center font-poppins rounded-b-[0.75rem] py-[0.5rem] px-[0.75rem] text-[0.875rem]`}
                                                >
                                                    <input type="radio" checked={selectedFilter === "sell"} className='scale-125 accent-[#0FB23C]' />
                                                    <p className='text-[#16161699]'>Reward: <span className='text-[#161616]'>10% Brokerage Commission</span></p>
                                                </button>
                                            )}
                                        </Menu.Item>

                                    </Menu.Items>
                                </Transition>
                            </>
                        )}
                    </Menu>
                    {/* <button className='flex items-center gap-[0.25rem] bg-[#F1F1F1] py-[0.5rem] px-[0.75rem] rounded-[0.75rem] font-poppins text-[0.875rem] text-[#161616]'>
                        <p>Bulk Actions</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <g clip-path="url(#clip0_1652_8268)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.27104 10.4712C9.14602 10.5962 8.97648 10.6664 8.79971 10.6664C8.62293 10.6664 8.45339 10.5962 8.32837 10.4712L4.55704 6.6999C4.49337 6.63841 4.44258 6.56484 4.40764 6.48351C4.3727 6.40217 4.35431 6.31469 4.35354 6.22617C4.35277 6.13765 4.36964 6.04986 4.40316 5.96793C4.43668 5.886 4.48618 5.81157 4.54878 5.74897C4.61137 5.68638 4.68581 5.63688 4.76774 5.60336C4.84967 5.56983 4.93746 5.55297 5.02598 5.55374C5.11449 5.55451 5.20197 5.5729 5.28331 5.60784C5.36465 5.64277 5.43821 5.69356 5.49971 5.75724L8.79971 9.05724L12.0997 5.75724C12.2254 5.6358 12.3938 5.5686 12.5686 5.57012C12.7434 5.57164 12.9106 5.64175 13.0343 5.76536C13.1579 5.88896 13.228 6.05617 13.2295 6.23097C13.231 6.40577 13.1638 6.57417 13.0424 6.6999L9.27104 10.4712Z" fill="#161616" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1652_8268">
                                    <rect width="16" height="16" fill="white" transform="translate(0.799805)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </button> */}
                    <CSVLink data={CSVdata} filename={"referral_management_data.csv"}>
                        <button className='bg-[#0FB23C1A] border border-[#0FB23C] rounded-[0.75rem] px-[2rem] py-[0.75rem] font-poppins text-[1rem] font-semibold text-[#16161699]'>Export to CSV</button>
                    </CSVLink>
                    <button onClick={() => exportData(JSON.stringify(body), "payment_management_data.json", "application/json")} className='bg-[#0FB23C1A] border border-[#0FB23C] rounded-[0.75rem] px-[2rem] py-[0.75rem] font-poppins text-[1rem] font-semibold text-[#16161699]'>Export to JSON</button>
                </div>
            </div>
            <div className='w-[100%] overflow-x-scroll no-scrollbar'>
                <table className="w-[100%] border-collapse bg-white rounded-[1.25rem] ">
                    <thead className="rounded-[1.25rem]">
                        <tr className="w-[100%]">
                            <td className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem] font-semibold`}>

                            </td>
                            {head.map((row) => (
                                <td
                                    className={`text-left whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                                    key={row.key}
                                >
                                    <div className='flex items-center justify-start gap-[0.25rem] cursor-pointer'>
                                        {row.label} {row.key !== "status" && <svg className='' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M8.14509 7.14497L6.00009 9.29497L3.85509 7.14497C3.76094 7.05082 3.63324 6.99792 3.50009 6.99792C3.36694 6.99792 3.23924 7.05082 3.14509 7.14497C3.05094 7.23912 2.99805 7.36682 2.99805 7.49997C2.99805 7.63312 3.05094 7.76082 3.14509 7.85497L5.64509 10.355C5.69157 10.4018 5.74687 10.439 5.8078 10.4644C5.86873 10.4898 5.93409 10.5029 6.00009 10.5029C6.0661 10.5029 6.13145 10.4898 6.19238 10.4644C6.25331 10.439 6.30861 10.4018 6.35509 10.355L8.85509 7.85497C8.90171 7.80835 8.93869 7.75301 8.96392 7.69209C8.98915 7.63118 9.00214 7.5659 9.00214 7.49997C9.00214 7.43404 8.98915 7.36876 8.96392 7.30784C8.93869 7.24693 8.90171 7.19159 8.85509 7.14497C8.80847 7.09835 8.75313 7.06137 8.69222 7.03614C8.63131 7.01091 8.56602 6.99792 8.50009 6.99792C8.43416 6.99792 8.36888 7.01091 8.30797 7.03614C8.24706 7.06137 8.19171 7.09835 8.14509 7.14497ZM3.85509 4.85497L6.00009 2.70497L8.14509 4.85497C8.19157 4.90183 8.24687 4.93903 8.3078 4.96442C8.36873 4.9898 8.43409 5.00287 8.50009 5.00287C8.5661 5.00287 8.63145 4.9898 8.69238 4.96442C8.75331 4.93903 8.80861 4.90183 8.85509 4.85497C8.90196 4.80849 8.93915 4.75319 8.96454 4.69226C8.98992 4.63133 9.00299 4.56598 9.00299 4.49997C9.00299 4.43396 8.98992 4.36861 8.96454 4.30768C8.93915 4.24675 8.90196 4.19145 8.85509 4.14497L6.35509 1.64497C6.30861 1.59811 6.25331 1.56091 6.19238 1.53552C6.13145 1.51014 6.0661 1.49707 6.00009 1.49707C5.93409 1.49707 5.86873 1.51014 5.8078 1.53552C5.74687 1.56091 5.69157 1.59811 5.64509 1.64497L3.14509 4.14497C3.09847 4.19159 3.06149 4.24693 3.03626 4.30784C3.01103 4.36876 2.99805 4.43404 2.99805 4.49997C2.99805 4.63312 3.05094 4.76082 3.14509 4.85497C3.23924 4.94912 3.36694 5.00202 3.50009 5.00202C3.63324 5.00202 3.76094 4.94912 3.85509 4.85497Z" fill="#161616" fill-opacity="0.6" />
                                        </svg>}
                                    </div>
                                </td>
                            ))}
                            <td className={`text-left whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem] font-semibold`}>

                            </td>
                        </tr>
                    </thead>
                    <tbody className="w-[100%]">
                        {currentItems?.map((data, index) => (
                            <tr key={data.id} className={index % 2 === 0 && "bg-[#F1F1F1]"}>
                                <td>
                                    {data.flag === true && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 14C20.1704 13.9999 20.3379 13.9563 20.4867 13.8733C20.6355 13.7902 20.7606 13.6706 20.8502 13.5256C20.9398 13.3806 20.9908 13.2152 20.9985 13.045C21.0061 12.8748 20.9702 12.7054 20.894 12.553L19.118 9L20.894 5.447C20.9702 5.29458 21.0061 5.12522 20.9985 4.95501C20.9908 4.78479 20.9398 4.61935 20.8502 4.47439C20.7606 4.32944 20.6355 4.20977 20.4867 4.12674C20.3379 4.04372 20.1704 4.00009 20 4H9V3C9 2.73478 8.89464 2.48043 8.70711 2.29289C8.51957 2.10536 8.26522 2 8 2C7.73478 2 7.48043 2.10536 7.29289 2.29289C7.10536 2.48043 7 2.73478 7 3V21C7 21.2652 7.10536 21.5196 7.29289 21.7071C7.48043 21.8946 7.73478 22 8 22C8.26522 22 8.51957 21.8946 8.70711 21.7071C8.89464 21.5196 9 21.2652 9 21V14H20Z" fill="#E7A600" />
                                    </svg>}
                                </td>
                                <td onClick={() => {
                                    setSelectedReferral({
                                        referralID: data.id,
                                        referring_user: data.referring_user,
                                        referred_user: data.referred_user,
                                        reward: data.reward,
                                        status: data.status,
                                        date: data.date,
                                        user_id: data.user_id,
                                        flag: data.flag,
                                        tethered_requirements:data.tethered_requirements
                                    })
                                    setIsOpen(!isOpen)
                                }} className="cursor-pointer lg:text-left whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#0FB23C]">
                                    {data.id}
                                </td>
                                <td className="lg:text-left whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                                    {data.referring_user}
                                </td>
                                <td className="lg:text-left whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#0FB23C]">
                                    {data.referred_user}
                                </td>

                                <td className="lg:text-left whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                                    {data.reward}
                                </td>

                                <td>
                                    <p className={`text-left max-w-max whitespace-nowrap px-[0.5rem] py-[0.125rem] font-poppins text-[0.875rem] ${data.status.toLowerCase() === "unprocessed" ? "bg-[#E7A6001A] text-[#E7A600]" : data.status === "approved" || data.status === "completed" ? "bg-[#0FB23C1A] text-[#018B28]" : "bg-[#B20F191A] text-[#B20F19]"} rounded-full`}>
                                        {data.status}
                                    </p>
                                </td>

                                <td className="lg:text-left whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                                    {ConvertData(data.date)}
                                </td>
                                {/* <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                        <g clip-path="url(#clip0_1652_8310)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.80029 14C6.80029 13.4696 7.01101 12.9609 7.38608 12.5858C7.76115 12.2107 8.26986 12 8.80029 12C9.33073 12 9.83943 12.2107 10.2145 12.5858C10.5896 12.9609 10.8003 13.4696 10.8003 14C10.8003 14.5304 10.5896 15.0391 10.2145 15.4142C9.83943 15.7893 9.33073 16 8.80029 16C8.26986 16 7.76115 15.7893 7.38608 15.4142C7.01101 15.0391 6.80029 14.5304 6.80029 14ZM6.80029 2C6.80029 1.46957 7.01101 0.960859 7.38608 0.585786C7.76115 0.210713 8.26986 0 8.80029 0C9.33073 0 9.83943 0.210713 10.2145 0.585786C10.5896 0.960859 10.8003 1.46957 10.8003 2C10.8003 2.53043 10.5896 3.03914 10.2145 3.41421C9.83943 3.78929 9.33073 4 8.80029 4C8.26986 4 7.76115 3.78929 7.38608 3.41421C7.01101 3.03914 6.80029 2.53043 6.80029 2ZM6.80029 7.8192C6.80029 7.28877 7.01101 6.78006 7.38608 6.40499C7.76115 6.02991 8.26986 5.8192 8.80029 5.8192C9.33073 5.8192 9.83943 6.02991 10.2145 6.40499C10.5896 6.78006 10.8003 7.28877 10.8003 7.8192C10.8003 8.34963 10.5896 8.85834 10.2145 9.23341C9.83943 9.60849 9.33073 9.8192 8.80029 9.8192C8.26986 9.8192 7.76115 9.60849 7.38608 9.23341C7.01101 8.85834 6.80029 8.34963 6.80029 7.8192Z" fill="#161616" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1652_8310">
                                                <rect width="16" height="16" fill="white" transform="matrix(0 -1 1 0 0.800293 16)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='mt-[1.5rem] flex lg:flex-row flex-col gap-[1rem] items-center justify-between'>
                <p className='font-poppins text-[0.875rem] text-[#16161699]'>Page {currentPage} of {pageCount} ({body.length} items)</p>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={<svg className='w-[1.5rem] h-[1.5rem]' xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                        <path d="M6.43613 10.1566C6.39102 10.2044 6.35576 10.2605 6.33235 10.3219C6.30894 10.3832 6.29785 10.4486 6.29971 10.5143C6.30157 10.5799 6.31634 10.6446 6.34318 10.7045C6.37001 10.7644 6.4084 10.8185 6.45613 10.8636C6.50387 10.9087 6.56002 10.944 6.62139 10.9674C6.68275 10.9908 6.74812 11.0019 6.81378 11C6.87943 10.9982 6.94407 10.9834 7.00401 10.9566C7.06395 10.9297 7.11802 10.8914 7.16313 10.8436L11.4131 6.34362C11.5009 6.25079 11.5498 6.12788 11.5498 6.00012C11.5498 5.87236 11.5009 5.74945 11.4131 5.65662L7.16313 1.15612C7.11832 1.10734 7.06426 1.06795 7.0041 1.04024C6.94394 1.01252 6.87887 0.997042 6.81267 0.99469C6.74647 0.992338 6.68047 1.00316 6.61849 1.02654C6.55651 1.04991 6.49979 1.08536 6.45163 1.13084C6.40347 1.17632 6.36483 1.23091 6.33795 1.29145C6.31106 1.35199 6.29648 1.41726 6.29504 1.48349C6.29359 1.54971 6.30532 1.61556 6.32955 1.67721C6.35377 1.73886 6.39 1.79509 6.43613 1.84262L10.3621 6.00012L6.43613 10.1566Z" fill="#161616" />
                    </svg>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<svg className='w-[1.5rem] h-[1.5rem]' xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                        <path d="M7.16348 1.84338C7.20859 1.79564 7.24385 1.73949 7.26726 1.67813C7.29067 1.61676 7.30176 1.55139 7.2999 1.48574C7.29804 1.42009 7.28327 1.35544 7.25643 1.2955C7.22959 1.23556 7.19121 1.18149 7.14348 1.13638C7.09574 1.09127 7.03959 1.05601 6.97822 1.0326C6.91686 1.00919 6.85148 0.998099 6.78583 0.999956C6.72018 1.00181 6.65554 1.01658 6.5956 1.04342C6.53565 1.07026 6.48159 1.10864 6.43648 1.15638L2.18648 5.65638C2.09871 5.74921 2.0498 5.87212 2.0498 5.99988C2.0498 6.12764 2.09871 6.25055 2.18648 6.34338L6.43648 10.8439C6.48129 10.8927 6.53534 10.9321 6.59551 10.9598C6.65567 10.9875 6.72074 11.003 6.78694 11.0053C6.85314 11.0077 6.91914 10.9968 6.98112 10.9735C7.0431 10.9501 7.09981 10.9146 7.14797 10.8692C7.19613 10.8237 7.23478 10.7691 7.26166 10.7086C7.28855 10.648 7.30313 10.5827 7.30457 10.5165C7.30602 10.4503 7.29429 10.3844 7.27006 10.3228C7.24584 10.2611 7.20961 10.2049 7.16348 10.1574L3.23748 5.99988L7.16348 1.84338Z" fill="#161616" />
                    </svg>}
                    renderOnZeroPageCount={null}
                    className='flex font-poppins items-center text-[0.875rem] text-[#161616] gap-[1rem]'
                    containerClassName='pagination'
                    pageLinkClassName='page-num'
                    activeClassName='bg-green-500 text-white h-[1.25rem] w-[1.25rem] flex items-center justify-center rounded-[0.125rem]'
                />
            </div>
            <ReferralManagement refresh={refresh} setRefresh={setRefresh} selectedReferral={selectedReferral} isOpen={isOpen} setIsOpen={setIsOpen} setIsOpenConfirm={setIsOpenConfirm} />
            <ConfirmReferralReward isOpen={isOpenConfirm} setIsOpen={setIsOpenConfirm} />

        </div>
    )
}

export default ReferralManagementTable