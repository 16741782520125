import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import axios from "../../axios";
import ColumnDataTable from "../Modals/ColumnDataTable";

function MainColumnChart() {
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [keyActiveUsers, setKeyActiveUsers] = useState([]);
  const [dates, setDates] = useState([]);
  const [dataSelected, setDataSelected] = useState(null);
  console.log(data);
  // console.log(activeUsers)
  console.log(dataSelected);
  useEffect(() => {
    const fetchData = async () => {
      axios.get("user-activity-chart").then((res) => {
        // console.log(res.data);
        setDates(res.data.map((d) => d.date));
        setActiveUsers(res.data.map((a) => a.active_users));
        setKeyActiveUsers(res.data.map((k) => k.key_active_users));
        setData(res.data);
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    let ids;
    if (dataSelected || dataSelected === 0) {
      ids = data[dataSelected].active_user_list;
    }
    console.log(ids);
    const fetchData = async () => {
      axios
        .post("barchart-users/", { user_ids: ids })
        .then((res) => {
          console.log(res);
          setDataTable(res.data);
          setIsOpen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (dataSelected || dataSelected === 0) {
      fetchData();
    }
  }, [data, dataSelected]);

  let data1 = [
    {
      name: "Active Users",
      data: activeUsers,
    },
    {
      name: "Key Active Users",
      data: keyActiveUsers,
    },
  ];
  const settings = {
    series: data1,
    options: {
      //   legend: {
      //     show: false,
      //   },
      chart: {
        // height: 650,
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          dataPointSelection: function (event, chartContext, config) {
            // console.log(config.seriesIndex);
            // console.log(config.dataPointIndex);
            setDataSelected(config.dataPointIndex);
          },
        },
      },
      // title: {
      //     text: 'Distibuted Treemap (different color for each cell)',
      //     align: 'center'
      // },
      //   colors: ["#30A12D", "#48C755", "#6EE873"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          borderRadiusApplication: "end", // 'around', 'end'
          borderRadiusWhenStacked: "last", // 'all', 'last'
          dataLabels: {
            enabled: false,
            total: {
              enabled: false,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: dates,
      },
      legend: {
        show: true,
        position: "top",
        offsetY: 0,
      },
      fill: {
        opacity: 1,
      },
      //   dataLabels: {
      //     enabled: true,
      //     formatter: function (val, opts) {
      //         console.log(opts.w)
      //         // return opts.w.config.series[0].data.map(dat => {
      //         //     console.log(dat)
      //         //     return val + " " + dat.y + " " + dat.z
      //         // })
      //         return val + " "
      //     },
      //     style: {
      //       fontSize: "16px",
      //       fontFamily: "Poppins, sans-serif",
      //       fontWeight: 600,
      //       whiteSpace: "pre-line",
      //       display: "inline",
      //       // colors: "#ffff"
      //     },
      //   },
    },
  };

  return (
    <>
      <ApexCharts
        options={settings.options}
        series={settings.series}
        type="bar"
        height={"90%"}
        width={"100%"}
      />
      <ColumnDataTable
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        body={dataTable}
        setDataSelected={setDataSelected}
        keyUsers={(dataSelected || dataSelected === 0) && data[dataSelected].key_active_user_list}
      />
    </>
  );
}

export default MainColumnChart;
