/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import axios from "../../axios";
import { Menu, Transition } from "@headlessui/react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

function EditFeedModal(props) {
  let completeButtonRef = useRef(null);
  //   const [comment, setComment] = useState("");
  const [file, setFile] = useState(null);
  const [otherDetails, setOtherDetails] = useState({
    id: "",
    topic: "",
    title: "",
    content: "",
    category: "",
    image_caption: "",
    symbol: "",
    subtitle: "",
    user: "",
    categoryNum: "",
    bullet_1: "",
    bullet_2: "",
    bullet_3: "",
    bullet_4: "",
    bullet_5: "",
    bullet_6: "",
  });

  //   const showRequestSuccess = (message) => {
  //     toast.success(message, {
  //       position: toast.POSITION.BOTTOM_CENTER,
  //     });
  //   };

  //   const showRequestFailed = (message) => {
  //     toast.error(message, {
  //       position: toast.POSITION.BOTTOM_CENTER,
  //       style: {
  //         backgroundColor: "black",
  //         color: "white",
  //       },
  //     });
  //   };

  //   console.log(file)

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setFile(props.selectedFeed.image)
    setOtherDetails({
      ...otherDetails,
      id: props.selectedFeed.id,
      topic: props.selectedFeed.topic,
      title: props.selectedFeed.title,
      content: props.selectedFeed.content,
      category: props.selectedFeed.category,
      categoryNum: props.selectedFeed.categoryNum,
      image_caption: props.selectedFeed.image_caption,
      symbol: props.selectedFeed.symbol,
      subtitle: props.selectedFeed.subtitle,
      user: props.selectedFeed.user,
      bullet_1: props.selectedFeed.bullet_1 ?? "",
      bullet_2: props.selectedFeed.bullet_2 ?? "",
      bullet_3: props.selectedFeed.bullet_3 ?? "",
      bullet_4: props.selectedFeed.bullet_4 ?? "",
      bullet_5: props.selectedFeed.bullet_5 ?? "",
      bullet_6: props.selectedFeed.bullet_6 ?? "",
    });
  }, [
    // otherDetails,
    props.selectedFeed.id,
    props.selectedFeed.category,
    props.selectedFeed.content,
    props.selectedFeed.image_caption,
    props.selectedFeed.subtitle,
    props.selectedFeed.symbol,
    props.selectedFeed.title,
    props.selectedFeed.topic,
  ]);

  const fileUploader = (e) => {
    const file = e.target.files?.[0];
    // console.log(file)
    if (file) {
      setFile(file);
    }
  };
  // console.log(props.selectedPayment)

  const handleSubmit = async () => {
    setLoading(true);
    if (file) {
      const form = new FormData();
      form.append("image", file);
      form.append("id", otherDetails.id);
      form.append("user", otherDetails.user);
      form.append("topic", otherDetails.topic);
      form.append("title", otherDetails.title);
      form.append("content", otherDetails.content);
      form.append("category", otherDetails.categoryNum);
      form.append("image_caption", otherDetails.image_caption);
      form.append("symbol", otherDetails.symbol);
      form.append("subtitle", otherDetails.subtitle);
      form.append("bullet_1", otherDetails.bullet_1);
      form.append("bullet_2", otherDetails.bullet_2);
      form.append("bullet_3", otherDetails.bullet_3);
      form.append("bullet_4", otherDetails.bullet_4);
      form.append("bullet_5", otherDetails.bullet_5);
      form.append("bullet_6", otherDetails.bullet_6);

      try {
        axios
          .put(
            "https://api-hamma-f0bcaabf77ea.herokuapp.com/portfolio/feeds/admin/",
            form,
            {
              headers: {
                Authorization: localStorage.getItem("access_token")
                  ? "Bearer " + localStorage.getItem("access_token")
                  : null,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            console.log(res);
            setOtherDetails({
              topic: "",
              title: "",
              content: "",
              category: "",
              image_caption: "",
              symbol: "",
              subtitle: "",
            });
            setLoading(false);
            props.showRequestSuccess("Successfully edited Idea");
            props.setRefresh(!props.refresh);
            props.setIsOpen(false);
          })
          .catch((err) => {
            console.log(err);
            props.showRequestFailed("Failed to edit Idea");
            setLoading(false);
          });
      } catch (error) {}
    } else {
      const form = new FormData();
      // form.append("image", file);
      form.append("id", otherDetails.id);
      form.append("user", otherDetails.user);
      form.append("topic", otherDetails.topic);
      form.append("title", otherDetails.title);
      form.append("content", otherDetails.content);
      form.append("category", otherDetails.categoryNum);
      form.append("image_caption", otherDetails.image_caption);
      form.append("symbol", otherDetails.symbol);
      form.append("subtitle", otherDetails.subtitle);
      form.append("bullet_1", otherDetails.bullet_1);
      form.append("bullet_2", otherDetails.bullet_2);
      form.append("bullet_3", otherDetails.bullet_3);
      form.append("bullet_4", otherDetails.bullet_4);
      form.append("bullet_5", otherDetails.bullet_5);
      form.append("bullet_6", otherDetails.bullet_6);

      try {
        axios
          .put(
            "https://api-hamma-f0bcaabf77ea.herokuapp.com/portfolio/feeds/admin/",
            form,
            {
              headers: {
                Authorization: localStorage.getItem("access_token")
                  ? "Bearer " + localStorage.getItem("access_token")
                  : null,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            console.log(res);
            setOtherDetails({
              topic: "",
              title: "",
              content: "",
              category: "",
              image_caption: "",
              symbol: "",
              subtitle: "",
            });
            setLoading(false);
            props.showRequestSuccess("Succcessfully edited Idea");
            props.setRefresh(!props.refresh);
            props.setIsOpen(false);
          })
          .catch((err) => {
            console.log(err);
            props.showRequestFailed("Failed to edit Idea");
            setLoading(false);
          });
      } catch (error) {}
    }
  };

  return (
    <Dialog
      open={props.isOpen}
      initialFocus={completeButtonRef}
      onClose={() => {
        setOtherDetails({
          topic: "",
          title: "",
          content: "",
          category: "",
          image_caption: "",
          symbol: "",
          subtitle: "",
        });
        props.setIsOpen(false);
      }}
      className="relative z-50 "
      as="div"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-[#15151566]" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center">
        {/* The actual dialog panel  */}

        <Dialog.Panel className="mx-auto lg:w-[38.3125rem] w-[95%] flex flex-col items-center bg-inherit rounded-[1.25rem] lg:p-[1.5rem]">
          <div className="bg-white w-[100%] rounded-[0.75rem] p-[1.5rem] lg:h-[600px] h-[500px] overflow-y-scroll">
            {/* <ToastContainer /> */}
            <h1 className="font-poppins font-semibold text-[#161616] text-center mb-[1rem]">
              Edit Idea
            </h1>
            {/* <p className="font-poppins text-[0.875rem] text-center text-[#16161699] mb-[1rem]">
              Created idea is saved as a draft. To publish, click the publish
              button.
            </p> */}
            <div className="w-[100%] flex flex-col gap-[1rem] mb-5">
              <div className="w-[100%]">
                <p className="mb-1">Topic</p>
                <input
                  onChange={(e) =>
                    setOtherDetails({ ...otherDetails, topic: e.target.value })
                  }
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="text"
                  value={otherDetails.topic}
                />
              </div>
              <div className="w-[100%]">
                <p className="mb-1">Title</p>
                <input
                  onChange={(e) =>
                    setOtherDetails({ ...otherDetails, title: e.target.value })
                  }
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="text"
                  value={otherDetails.title}
                />
              </div>
              <div className="w-[100%] relative">
                <p className="mb-1">Category</p>
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button className="bg-[#F1F1F1] py-[0.75rem] px-[1rem] rounded-[0.75rem] w-[100%] mb-[1rem] font-poppins outline-[#0FB23C]">
                        {otherDetails.category !== ""
                          ? otherDetails.category
                          : "Select Category"}
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute top-14 shadow-[0_4px_16px_2px_rgba(1,1,1,0.15)] lg:w-[15.2rem] w-[70%] z-10 lg:mt-0 mt-[0.1rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] bg-[#F1F1F1] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col">
                          {props.feedsCat?.map((cat) => (
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() =>
                                    setOtherDetails({
                                      ...otherDetails,
                                      category: cat.name,
                                      categoryNum: cat.id,
                                    })
                                  }
                                  className={`group gap-[1rem] justify-start flex w-full items-center font-poppins rounded-t-[0.75rem] py-[0.5rem] px-[0.75rem] text-[0.875rem]`}
                                >
                                  {cat.name}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
                {/* <input
                    className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                    type="text"
                  /> */}
              </div>

              <div className="w-[100%] relative">
                <p className="mb-1">Symbol</p>
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button className="bg-[#F1F1F1] py-[0.75rem] px-[1rem] rounded-[0.75rem] w-[100%] mb-[1rem] font-poppins outline-[#0FB23C]">
                        {otherDetails.symbol !== ""
                          ? otherDetails.symbol
                          : "Select Symbol"}
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute top-14 shadow-[0_4px_16px_2px_rgba(1,1,1,0.15)] lg:w-[15.2rem] h-[250px] overflow-y-scroll w-[70%] z-10 lg:mt-0 mt-[0.1rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] bg-[#F1F1F1] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col">
                          {props.symbols
                            ?.sort((a, b) => {
                              if (a.symbol[0] < b.symbol[0]) {
                                return -1;
                              }
                              if (a.symbol[0] > b.symbol[0]) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((cat) => (
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() =>
                                      setOtherDetails({
                                        ...otherDetails,
                                        symbol: cat.symbol[0],
                                      })
                                    }
                                    className={`group gap-[1rem] justify-start flex w-full items-center font-poppins rounded-t-[0.75rem] py-[0.5rem] px-[0.75rem] text-[0.875rem]`}
                                  >
                                    {cat.symbol[0]}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="w-[100%]">
                <p className="mb-1">Image</p>
                <input
                  onChange={fileUploader}
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="file"
                />
              </div>
              <div className="w-[100%]">
                <p className="mb-1">Image caption</p>
                <input
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      image_caption: e.target.value,
                    })
                  }
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="text"
                  value={otherDetails.image_caption}
                />
              </div>
              <div className="w-[100%]">
                <p className="mb-1">Subtitle</p>
                <input
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      subtitle: e.target.value,
                    })
                  }
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="text"
                  value={otherDetails.subtitle}
                />
              </div>
              {/* <div>
                <p className="mb-1">Content</p>
                <textarea
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      content: e.target.value,
                    })
                  }
                  rows={5}
                  className="bg-[#F1F1F1] py-[0.75rem] px-[1rem] rounded-[0.75rem] w-[100%] mb-[1rem] font-poppins outline-[#0FB23C]"
                  type="text"
                  value={otherDetails.content}
                  //   placeholder="Some reasons behind the decision..."
                />
              </div> */}
              <div className="w-[100%]">
                <p className="mb-1">Bullet point 1</p>
                <textarea
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      bullet_1: e.target.value,
                    })
                  }
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="text"
                  value={otherDetails.bullet_1}
                />
              </div>
              <div className="w-[100%]">
                <p className="mb-1">Bullet point 2</p>
                <textarea
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      bullet_2: e.target.value,
                    })
                  }
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="text"
                  value={otherDetails.bullet_2}
                />
              </div>
              <div className="w-[100%]">
                <p className="mb-1">Bullet point 3</p>
                <textarea
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      bullet_3: e.target.value,
                    })
                  }
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="text"
                  value={otherDetails.bullet_3}
                />
              </div>
              <div className="w-[100%]">
                <p className="mb-1">Bullet point 4</p>
                <textarea
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      bullet_4: e.target.value,
                    })
                  }
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="text"
                  value={otherDetails.bullet_4}
                />
              </div>
              <div className="w-[100%]">
                <p className="mb-1">Bullet point 5</p>
                <textarea
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      bullet_5: e.target.value,
                    })
                  }
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="text"
                  value={otherDetails.bullet_5}
                />
              </div>
              <div className="w-[100%]">
                <p className="mb-1">Bullet point 6</p>
                <textarea
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      bullet_6: e.target.value,
                    })
                  }
                  className="border w-[100%] rounded-[0.75rem] px-[0.5rem] py-[0.25rem] bg-[#f1f1f1] font-poppins outline-[#0fb23c] border-gray-600"
                  type="text"
                  value={otherDetails.bullet_6}
                />
              </div>
            </div>

            <div className="py-[0.5rem] flex lg:flex-row flex-col items-center gap-[1rem]">
              <button
                onClick={() => {
                  setOtherDetails({
                    topic: "",
                    title: "",
                    content: "",
                    category: "",
                    image_caption: "",
                    symbol: "",
                    subtitle: "",
                  });
                  props.setIsOpen(false);
                }}
                className="lg:w-[50%] w-[100%] font-poppins font-semibold text-[#B20F19] border border-[#B20F19] rounded-[0.75rem] px-[2rem] py-[0.75rem] whitespace-nowrap"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={loading}
                className="lg:w-[50%] w-[100%] font-poppins font-semibold text-white border bg-[#0FB23C] border-[#0FB23C] rounded-[0.75rem] px-[2rem] py-[0.75rem] whitespace-nowrap"
              >
                {loading ? "Updating..." : "Update Idea"}
              </button>
            </div>
            <p
              ref={completeButtonRef}
              onClick={() => props.setIsOpen(false)}
              className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
            ></p>
          </div>
          <div
            onClick={() => {
              setOtherDetails({
                topic: "",
                title: "",
                content: "",
                category: "",
                image_caption: "",
                symbol: "",
                subtitle: "",
              });
              props.setIsOpen(!props.isOpen);
            }}
            className="bg-white cursor-pointer rounded-full w-[3.125rem] h-[3.125rem] flex items-center justify-center mt-[2rem]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M18.8002 5.70998C18.7077 5.61728 18.5978 5.54373 18.4768 5.49355C18.3559 5.44337 18.2262 5.41754 18.0952 5.41754C17.9643 5.41754 17.8346 5.44337 17.7136 5.49355C17.5926 5.54373 17.4827 5.61728 17.3902 5.70998L12.5002 10.59L7.61022 5.69998C7.51764 5.6074 7.40773 5.53396 7.28677 5.48385C7.1658 5.43375 7.03615 5.40796 6.90522 5.40796C6.77429 5.40796 6.64464 5.43375 6.52368 5.48385C6.40272 5.53396 6.29281 5.6074 6.20022 5.69998C6.10764 5.79256 6.0342 5.90247 5.9841 6.02344C5.93399 6.1444 5.9082 6.27405 5.9082 6.40498C5.9082 6.53591 5.93399 6.66556 5.9841 6.78652C6.0342 6.90749 6.10764 7.0174 6.20022 7.10998L11.0902 12L6.20022 16.89C6.10764 16.9826 6.0342 17.0925 5.9841 17.2134C5.93399 17.3344 5.9082 17.464 5.9082 17.595C5.9082 17.7259 5.93399 17.8556 5.9841 17.9765C6.0342 18.0975 6.10764 18.2074 6.20022 18.3C6.29281 18.3926 6.40272 18.466 6.52368 18.5161C6.64464 18.5662 6.77429 18.592 6.90522 18.592C7.03615 18.592 7.1658 18.5662 7.28677 18.5161C7.40773 18.466 7.51764 18.3926 7.61022 18.3L12.5002 13.41L17.3902 18.3C17.4828 18.3926 17.5927 18.466 17.7137 18.5161C17.8346 18.5662 17.9643 18.592 18.0952 18.592C18.2262 18.592 18.3558 18.5662 18.4768 18.5161C18.5977 18.466 18.7076 18.3926 18.8002 18.3C18.8928 18.2074 18.9662 18.0975 19.0163 17.9765C19.0665 17.8556 19.0922 17.7259 19.0922 17.595C19.0922 17.464 19.0665 17.3344 19.0163 17.2134C18.9662 17.0925 18.8928 16.9826 18.8002 16.89L13.9102 12L18.8002 7.10998C19.1802 6.72998 19.1802 6.08998 18.8002 5.70998Z"
                fill="#161616"
              />
            </svg>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default EditFeedModal;
