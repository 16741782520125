import React, { Fragment, forwardRef, useEffect, useState } from "react";
import CreateFeedModal from "../Modals/CreateFeedModal";
import axios from "../../axios";
import { Menu, Transition } from "@headlessui/react";
import EditFeedModal from "../Modals/EditFeedModal";
import DeleteFeed from "../Modals/DeleteFeed";
import PublishFeed from "../Modals/PublishFeed";
import UnpublishFeed from "../Modals/UnpublishFeed";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// function truncateString(str, num) {
//   if (str.length <= num) {
//     return str;
//   }
//   return str.slice(0, num) + "...";
// }

const convertDate = (dateString) => {
  const dateObject = new Date(dateString);
  const readableDate = dateObject.toLocaleString();
  return readableDate;
};

// function convertDateFormatWithAddedDay(inputDate) {
//   const date = new Date(inputDate);

//   const year = date.getFullYear();
//   const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
//   const day = date.getDate().toString().padStart(2, "0");

//   const formattedDate = `${year}-${month}-${day}`;

//   return formattedDate;
// }

function Ideas() {
  const [open, setIsOpen] = useState(false);
  const [openEdit, setIsOpenEdit] = useState(false);
  const [openPublish, setIsOpenPublish] = useState(false);
  const [openUnPublish, setIsOpenUnPublish] = useState(false);
  const [openDelete, setIsOpenDelete] = useState(false);
  const [feedsCat, setFeedsCat] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterByStock, setFilterByStock] = useState("");
  const [search, setSearch] = useState("");
  const [selectedFeed, setSelectedFeed] = useState({
    id: "",
    topic: "",
    title: "",
    content: "",
    category: "",
    image_caption: "",
    symbol: "",
    subtitle: "",
    image: "",
    categoryNum: "",
    user: "",
    bullet_1: "",
    bullet_2: "",
    bullet_3: "",
    bullet_4: "",
    bullet_5: "",
    bullet_6: "",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  // console.log(feedsCat);
  // console.log(selectedFeed);
  console.log(startDate);
  console.log(endDate);
  console.log(firstDate);
  console.log(secondDate);

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="bg-[#ffff]" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  useEffect(() => {
    axios
      .get(
        "https://api-hamma-f0bcaabf77ea.herokuapp.com/portfolio/feeds/category/"
      )
      .then((res) => {
        console.log(res);
        setFeedsCat(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("https://api-hamma-f0bcaabf77ea.herokuapp.com/portfolio/symbols/")
      .then((res) => {
        console.log(res);
        setSymbols(
          res.data.map((item) => {
            return { symbol: Object.keys(item), id: Object.values(item) };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        "https://api-hamma-f0bcaabf77ea.herokuapp.com/portfolio/feeds/admin/"
      )
      .then((res) => {
        console.log(res);
        setFeeds(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  const onChange = (dates) => {
    setFilter("");
    setSearch("");
    setFilterByStock("");
    const [start, end] = dates;
    setFirstDate(convertDate(start));
    setSecondDate(convertDate(end));
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="lg:px-[3rem] px-[1rem] py-[1rem]">
      <ToastContainer />
      <div>
        <div className="flex flex-col lg:flex-row items-center gap-5 justify-end ">
          <div className="flex items-center gap-[0.25rem] px-[0.75rem] py-[0.5rem] bg-[white] rounded-[0.75rem]">
            {/* <p>21 Dec - 27 Dec</p> */}
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              closeOnScroll={true}
              style={{ background: "#ffff" }}
              customInput={<ExampleCustomInput />}
              open={openDatePicker}
            />
            <svg
              className="cursor-pointer"
              onClick={() => setOpenDatePicker(!openDatePicker)}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2703_2742)">
                <path
                  d="M13 2.5H3C2.17157 2.5 1.5 3.17157 1.5 4V13C1.5 13.8284 2.17157 14.5 3 14.5H13C13.8284 14.5 14.5 13.8284 14.5 13V4C14.5 3.17157 13.8284 2.5 13 2.5Z"
                  stroke="#161616"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.25 8C9.66421 8 10 7.66421 10 7.25C10 6.83579 9.66421 6.5 9.25 6.5C8.83579 6.5 8.5 6.83579 8.5 7.25C8.5 7.66421 8.83579 8 9.25 8Z"
                  fill="#161616"
                />
                <path
                  d="M11.75 8C12.1642 8 12.5 7.66421 12.5 7.25C12.5 6.83579 12.1642 6.5 11.75 6.5C11.3358 6.5 11 6.83579 11 7.25C11 7.66421 11.3358 8 11.75 8Z"
                  fill="#161616"
                />
                <path
                  d="M9.25 10.5C9.66421 10.5 10 10.1642 10 9.75C10 9.33579 9.66421 9 9.25 9C8.83579 9 8.5 9.33579 8.5 9.75C8.5 10.1642 8.83579 10.5 9.25 10.5Z"
                  fill="#161616"
                />
                <path
                  d="M11.75 10.5C12.1642 10.5 12.5 10.1642 12.5 9.75C12.5 9.33579 12.1642 9 11.75 9C11.3358 9 11 9.33579 11 9.75C11 10.1642 11.3358 10.5 11.75 10.5Z"
                  fill="#161616"
                />
                <path
                  d="M4.25 10.5C4.66421 10.5 5 10.1642 5 9.75C5 9.33579 4.66421 9 4.25 9C3.83579 9 3.5 9.33579 3.5 9.75C3.5 10.1642 3.83579 10.5 4.25 10.5Z"
                  fill="#161616"
                />
                <path
                  d="M6.75 10.5C7.16421 10.5 7.5 10.1642 7.5 9.75C7.5 9.33579 7.16421 9 6.75 9C6.33579 9 6 9.33579 6 9.75C6 10.1642 6.33579 10.5 6.75 10.5Z"
                  fill="#161616"
                />
                <path
                  d="M4.25 13C4.66421 13 5 12.6642 5 12.25C5 11.8358 4.66421 11.5 4.25 11.5C3.83579 11.5 3.5 11.8358 3.5 12.25C3.5 12.6642 3.83579 13 4.25 13Z"
                  fill="#161616"
                />
                <path
                  d="M6.75 13C7.16421 13 7.5 12.6642 7.5 12.25C7.5 11.8358 7.16421 11.5 6.75 11.5C6.33579 11.5 6 11.8358 6 12.25C6 12.6642 6.33579 13 6.75 13Z"
                  fill="#161616"
                />
                <path
                  d="M9.25 13C9.66421 13 10 12.6642 10 12.25C10 11.8358 9.66421 11.5 9.25 11.5C8.83579 11.5 8.5 11.8358 8.5 12.25C8.5 12.6642 8.83579 13 9.25 13Z"
                  fill="#161616"
                />
                <path
                  d="M4 1.5V2.5M12 1.5V2.5"
                  stroke="#161616"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.5 5H1.5"
                  stroke="#161616"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2703_2742">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="relative">
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button className="bg-[white] py-[0.5rem] px-[1rem] rounded-[0.75rem] font-poppins outline-[#0FB23C]">
                    {filter ? filter : "Filter by Category"}
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute top-14 left-0 shadow-[0_4px_16px_2px_rgba(1,1,1,0.15)] lg:w-[15.2rem] w-[13rem] z-10 lg:mt-0 mt-[0.1rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] bg-[#F1F1F1] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col">
                      {feedsCat?.map((cat) => (
                        <Menu.Item key={cat.id}>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setSearch("");
                                setFirstDate(null);
                                setSecondDate(null);
                                setFilter(cat.name);
                                setFilterByStock("");
                              }}
                              key={cat.name}
                              className={`group gap-[1rem] justify-start flex w-full items-center font-poppins rounded-t-[0.75rem] py-[0.5rem] px-[0.75rem] text-[0.875rem]`}
                            >
                              {cat.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
          <div className="relative">
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button className="bg-[white] py-[0.5rem] px-[1rem] rounded-[0.75rem] font-poppins outline-[#0FB23C]">
                    {filterByStock ? filterByStock : "Filter by Stock"}
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute top-14 left-0 shadow-[0_4px_16px_2px_rgba(1,1,1,0.15)] lg:w-[15.2rem] h-[25rem] overflow-y-scroll w-[13rem] z-10 lg:mt-0 mt-[0.1rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] bg-[#F1F1F1] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col">
                      {symbols?.map((cat) => (
                        <Menu.Item key={cat.id}>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setSearch("");
                                setFirstDate(null);
                                setSecondDate(null);
                                setFilter("");
                                setFilterByStock(cat.symbol);
                              }}
                              key={cat.id}
                              className={`group gap-[1rem] justify-start flex w-full items-center font-poppins rounded-t-[0.75rem] py-[0.5rem] px-[0.75rem] text-[0.875rem]`}
                            >
                              {cat.symbol}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
          <input
            onChange={(e) => {
              setFilter("");
              setFilterByStock("");
              setFirstDate(null);
              setSecondDate(null);
              setSearch(e.target.value);
            }}
            className="py-[0.5rem] outline-none px-[0.5rem] rounded-[10px]"
            type="text"
            placeholder="Search by title"
          />
          <button
            onClick={() => setIsOpen(true)}
            className="bg-green-500 text-white p-2 rounded-[5px]"
          >
            Create Idea
          </button>
        </div>
        <div className="mt-5 flex flex-col gap-5">
          {feeds
            .filter((fee) => {
              if (filter !== "") {
                return fee.category_name === filter;
              } else if (search !== "") {
                return fee.title.toLowerCase().includes(search);
              } else if (firstDate && secondDate) {
                return (
                  convertDate(fee.published_date) >= firstDate &&
                  convertDate(fee.published_date) <= secondDate
                );
              } else if (filterByStock !== "") {
                return fee.stock_symbol === filterByStock[0];
              } else {
                return fee;
              }
            })
            .map((feed) => (
              <div
                key={feed.id}
                className="flex flex-col lg:flex-row items-center bg-white rounded-[10px] p-5"
              >
                <div className="shrink-0">
                  <img
                    className="shrink-0 w-[300px] h-[200px] rounded-[10px]"
                    src={feed.image}
                    alt=""
                  />
                  <div className="flex flex-col gap-5 mt-5">
                    <button
                      onClick={() => {
                        setSelectedFeed({
                          id: feed.id,
                          image: feed.image,
                          topic: feed.topic,
                          title: feed.title,
                          content: feed.content,
                          category: feed.category_name,
                          image_caption: feed.image,
                          symbol: feed.stock_symbol,
                          subtitle: feed.subtitle,
                          categoryNum: feed.category,
                          user: feed.user,
                        });
                        if (feed.status === "published") {
                          setIsOpenUnPublish(true);
                        } else {
                          setIsOpenPublish(true);
                        }
                      }}
                      className={`${
                        feed.status === "draft" || feed.status === "unpublished"
                          ? "bg-green-500"
                          : "bg-gray-500"
                      } text-white p-2 rounded-[5px]`}
                    >
                      {feed.status === "draft" || feed.status === "unpublished"
                        ? "Publish"
                        : "Unpublish"}
                    </button>
                    <button
                      onClick={() => {
                        setSelectedFeed({
                          id: feed.id,
                          image: feed.image,
                          topic: feed.topic,
                          title: feed.title,
                          content: feed.content,
                          category: feed.category_name,
                          image_caption: feed.image_caption,
                          symbol: feed.stock_symbol,
                          subtitle: feed.subtitle,
                          categoryNum: feed.category,
                          user: feed.user,
                          bullet_1: feed.bullet_1,
                          bullet_2: feed.bullet_2,
                          bullet_3: feed.bullet_3,
                          bullet_4: feed.bullet_4,
                          bullet_5: feed.bullet_5,
                          bullet_6: feed.bullet_6,
                        });
                        setIsOpenEdit(true);
                      }}
                      className="text-white p-2 rounded-[5px] bg-yellow-500"
                    >
                      Edit
                    </button>
                    {/* <button
                      onClick={() => {
                        setSelectedFeed({
                          id: feed.id,
                          image: feed.image,
                          topic: feed.topic,
                          title: feed.title,
                          content: feed.content,
                          category: feed.category_name,
                          image_caption: feed.image,
                          symbol: feed.stock_symbol,
                          subtitle: feed.subtitle,
                          categoryNum: feed.category,
                          user: feed.user,
                        });
                        setIsOpenDelete(true);
                      }}
                      className="text-white p-2 rounded-[5px] bg-red-500"
                    >
                      Delete
                    </button> */}
                  </div>
                </div>
                <div className="bg-gray-600 w-[2px] mx-5"></div>
                <div className="flex flex-col mt-5 lg:mt-0 justify-between gap-[1rem]">
                  <p className="pl-1 lg:pl-0">
                    Topic:{" "}
                    <span className="font-semibold text-[1.15rem]">
                      {feed.topic}
                    </span>
                  </p>
                  <p className="pl-1 lg:pl-0">
                    Title:{" "}
                    <span className="font-semibold text-[1.15rem]">
                      {feed.title}
                    </span>
                  </p>
                  <p className="pl-1 lg:pl-0">
                    Image Caption:{" "}
                    <span className="font-semibold text-[1.15rem]">
                      {feed.image_caption}
                    </span>
                  </p>
                  <p className="pl-1 lg:pl-0">
                    Stock Symbol:{" "}
                    <span className="font-semibold text-[1.15rem]">
                      {feed.stock_symbol}
                    </span>
                  </p>
                  <p className="pl-1 lg:pl-0">
                    Category:{" "}
                    <span className="font-semibold text-[1.15rem]">
                      {feed.category_name}
                    </span>
                  </p>
                  <p className="pl-1 lg:pl-0">
                    Subtitle:{" "}
                    <span className="font-semibold text-[1.15rem]">
                      {feed.subtitle}
                    </span>
                  </p>
                  <p className="pl-1 lg:pl-0">
                    Status:{" "}
                    <span className="font-semibold text-[1.15rem]">
                      {feed.status}
                    </span>
                  </p>
                  <p className="pl-1 lg:pl-0">
                    Category:{" "}
                    <span className="font-semibold text-[1.15rem]">
                      {feed.category}
                    </span>
                  </p>
                  <p className="flex gap-1 pl-1 lg:pl-0">
                    Posted Date:{" "}
                    <span className="font-semibold text-[1.15rem]">
                      {convertDate(feed.published_date)}
                    </span>
                  </p>
                  {feed.bullet_1 && feed.bullet_1 !== "undefined" && (
                    <p className="pl-1 lg:pl-0">
                      Bullet 1:{" "}
                      <span className="font-semibold text-[1.15rem]">
                        {feed.bullet_1}
                      </span>
                    </p>
                  )}
                  {feed.bullet_2 && feed.bullet_2 !== "undefined" && (
                    <p className="pl-1 lg:pl-0">
                      Bullet 2:{" "}
                      <span className="font-semibold text-[1.15rem]">
                        {feed.bullet_2}
                      </span>
                    </p>
                  )}
                  {feed.bullet_3 && feed.bullet_3 !== "undefined" && (
                    <p className="pl-1 lg:pl-0">
                      Bullet 3:{" "}
                      <span className="font-semibold text-[1.15rem]">
                        {feed.bullet_3}
                      </span>
                    </p>
                  )}
                  {feed.bullet_4 && feed.bullet_4 !== "undefined" && (
                    <p className="pl-1 lg:pl-0">
                      Bullet 4:{" "}
                      <span className="font-semibold text-[1.15rem]">
                        {feed.bullet_4}
                      </span>
                    </p>
                  )}
                  {feed.bullet_5 && feed.bullet_5 !== "undefined" && (
                    <p className="pl-1 lg:pl-0">
                      Bullet 5:{" "}
                      <span className="font-semibold text-[1.15rem]">
                        {feed.bullet_5}
                      </span>
                    </p>
                  )}
                  {feed.bullet_6 && feed.bullet_6 !== "undefined" && (
                    <p className="pl-1 lg:pl-0">
                      Bullet 6:{" "}
                      <span className="font-semibold text-[1.15rem]">
                        {feed.bullet_6}
                      </span>
                    </p>
                  )}
                  <div className="flex lg:gap-5 gap-3 lg:text-[1rem] text-[0.7rem] mt-5 lg:mt-0 items-center">
                    <p className="whitespace-nowrap">
                      Likes:{" "}
                      <span className="font-semibold lg:text-[1.15rem]">
                        {feed.num_like}
                      </span>
                    </p>
                    <p className="whitespace-nowrap">
                      Agrees:{" "}
                      <span className="font-semibold lg:text-[1.15rem]">
                        {feed.num_agree}
                      </span>
                    </p>
                    <p className="whitespace-nowrap">
                      Disagrees:{" "}
                      <span className="font-semibold lg:text-[1.15rem]">
                        {feed.num_disagree}
                      </span>
                    </p>
                    <p className="whitespace-nowrap">
                      Trading:{" "}
                      <span className="font-semibold lg:text-[1.15rem]">
                        {feed.num_trading}
                      </span>
                    </p>
                    <p className="whitespace-nowrap">
                      Monitoring:{" "}
                      <span className="font-semibold lg:text-[1.15rem]">
                        {feed.num_monitoring}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <CreateFeedModal
        isOpen={open}
        setIsOpen={setIsOpen}
        feedsCat={feedsCat}
        symbols={symbols}
        refresh={refresh}
        setRefresh={setRefresh}
        showRequestFailed={showRequestFailed}
        showRequestSuccess={showRequestSuccess}
      />
      <EditFeedModal
        isOpen={openEdit}
        setIsOpen={setIsOpenEdit}
        feedsCat={feedsCat}
        symbols={symbols}
        refresh={refresh}
        setRefresh={setRefresh}
        selectedFeed={selectedFeed}
        showRequestFailed={showRequestFailed}
        showRequestSuccess={showRequestSuccess}
      />
      <PublishFeed
        isOpen={openPublish}
        setIsOpen={setIsOpenPublish}
        feedsCat={feedsCat}
        symbols={symbols}
        refresh={refresh}
        setRefresh={setRefresh}
        selectedFeed={selectedFeed}
        showRequestFailed={showRequestFailed}
        showRequestSuccess={showRequestSuccess}
      />
      <UnpublishFeed
        isOpen={openUnPublish}
        setIsOpen={setIsOpenUnPublish}
        feedsCat={feedsCat}
        symbols={symbols}
        refresh={refresh}
        setRefresh={setRefresh}
        selectedFeed={selectedFeed}
        showRequestFailed={showRequestFailed}
        showRequestSuccess={showRequestSuccess}
      />
      <DeleteFeed
        isOpen={openDelete}
        setIsOpen={setIsOpenDelete}
        feedsCat={feedsCat}
        symbols={symbols}
        refresh={refresh}
        setRefresh={setRefresh}
        selectedFeed={selectedFeed}
      />
    </div>
  );
}

export default Ideas;
