import React from 'react'
import SideMenu from '../components/SideMenu'
import TopMenu from '../components/TopMenu'
import { useLocation } from 'react-router-dom'
import Analytics from '../components/HomeModules/Analytics'
import Users from '../components/HomeModules/Users'
import Trades from '../components/HomeModules/Trades'
import Receipts from '../components/HomeModules/Receipts'
import Referrals from '../components/HomeModules/Referrals'
import Withdrawal from '../components/HomeModules/Withdrawal'
import Activity from '../components/HomeModules/Activity'
import BrowsingUsers from '../components/HomeModules/BrowsingUsers'
import Ideas from '../components/HomeModules/Ideas'

function Home() {
  const params = useLocation()
  const link = params.pathname.split("/")[1]
  const isAnalyst = localStorage.getItem("is_analyst")
  console.log(isAnalyst)
  console.log(link)
  return (
    <div className='flex lg:h-[100vh]'>
      {isAnalyst === "false" && <SideMenu route={link}/>}
      <div className={`bg-[#EEF5EF] ${isAnalyst === "true" ? "lg:w-[100%]" : "lg:w-[90%]"} w-[100%] lg:h-[100%] lg:overflow-y-scroll lg:relative`}>
        <TopMenu route={link}/>
        {link === "analytics" && <Analytics/>}
        {link === "users" && <Users/>}
        {link === "trades" && <Trades/>}
        {link === "receipts" && <Receipts/>}
        {link === "referrals" && <Referrals/>}
        {link === "withdrawal" && <Withdrawal/>}
        {link === "activity" && <Activity/>}
        {link === "browsing-users" && <BrowsingUsers/>}
        {link === "ideas" && <Ideas/>}
      </div>
    </div>
  )
}

export default Home