import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Login from "./pages/Login";
// import Register from "./pages/Register";

function App() {
  return (
    <div className="">
      <Router>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/:route' element={<Home />} />
          {/* <Route path='/register' element={<Register />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
