/* eslint-disable array-callback-return */
import React from 'react'
import ApexCharts from 'react-apexcharts'



function MainTreeMapChart({ data }) {
    console.log(data)
    const settings = {

        series: [
            {
                data: data.map(main => ({x:main.symbol, y:main.num_positions}))
            }
        ],
        options: {
            legend: {
                show: false
            },
            chart: {
                height: 350,
                type: 'treemap',
                toolbar: {
                    show: false
                }
            },
            // title: {
            //     text: 'Distibuted Treemap (different color for each cell)',
            //     align: 'center'
            // },
            colors: [
                '#30A12D',
                '#48C755',
                '#6EE873'
            ],
            plotOptions: {
                treemap: {
                    distributed: true,
                    enableShades: false
                }
            },
            dataLabels: {
                enabled: true,
                // formatter: function (val, opts) {
                //     console.log(opts.w)
                //     // return opts.w.config.series[0].data.map(dat => {
                //     //     console.log(dat)
                //     //     return val + " " + dat.y + " " + dat.z
                //     // })
                //     return val + " " 
                // },
                style: {
                    fontSize: '16px',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    whiteSpace: "pre-line",
                    display: "inline"
                    // colors: "#ffff"
                },
            }
        },


    };

    return (
        <ApexCharts options={settings.options} series={settings.series} type="treemap" height={"90%"} width={"100%"} />
    )
}

export default MainTreeMapChart