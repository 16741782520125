/* eslint-disable no-unused-vars */
import { Dialog } from '@headlessui/react'
import React, { useRef, useState } from 'react'
import axios from '../../axios';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import html2pdf from 'html2pdf.js';

function ConvertData(params) {
    const date = new Date(params);

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };

    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
}

function WithdrawalManagement(props) {
    let completeButtonRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [flagLoading, setFlagLoading] = useState(false)
    console.log(props.selectedWithdrawal)
    // const handleApprove = async () => {
    //     setLoading(true)
    //     try {
    //         axios.post("withdrawals/", {
    //             "id": props.id,
    //             "action": "approved",
    //             "type": props.selectedWithdrawal.type
    //         }).then(res => {
    //             console.log(res)
    //             if (res.data === "Insufficient fund") {
    //                 alert(res.data)
    //             }
    //             setLoading(false)
    //             props.setRefresh(!props.refresh)
    //             props.setIsOpen(false)
    //         }).catch(err => {
    //             console.log(err)
    //             setLoading(false)
    //         })
    //     } catch (error) {

    //     }

    // }

    const CSVdata = [props.selectedWithdrawal]

    const exportData = (data, fileName, type) => {
        // Create a link and download the file
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const handleDownload = () => {
        // Get the HTML element to capture
        const elementToCapture = document.getElementById('captureElement');

        // Options for html2pdf
        const options = {
            margin: 10,
            filename: 'page-document.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        // Use html2pdf to convert the element to a PDF and trigger the download
        html2pdf(elementToCapture, options);
    };

    // const handleDecline = async () => {
    //     setLoading(true)
    //     try {
    //         axios.post("withdrawals/", {
    //             "id": props.id,
    //             "action": "rejected",
    //             type: props.selectedWithdrawal.type
    //         }).then(res => {
    //             console.log(res)
    //             setLoading(false)
    //             props.setRefresh(!props.refresh)
    //             props.setIsOpen(false)
    //         }).catch(err => {
    //             console.log(err)
    //             setLoading(false)
    //         })
    //     } catch (error) {

    //     }

    // }

    const FlagWithdrawal = async () => {
        setFlagLoading(true)
        if (props.selectedWithdrawal.flag) {
            axios.post(`flag-withdrawal-request/${props.selectedWithdrawal.withdrawalID}/`, { keyword: "unflag", type: props.selectedWithdrawal.type }).then(res => {
                console.log(res)
                props.setRefresh(!props.refresh)
                setFlagLoading(false)
            }).catch(err => {
                console.log(err)
                alert("Failed to flag this withdrawal")
                setFlagLoading(false)
            })
        } else {
            axios.post(`flag-withdrawal-request/${props.selectedWithdrawal.withdrawalID}/`, { keyword: "flag", type: props.selectedWithdrawal.type }).then(res => {
                console.log(res)
                props.setRefresh(!props.refresh)
                setFlagLoading(false)
            }).catch(err => {
                console.log(err)
                alert("Failed to flag this withdrawal")
                setFlagLoading(false)
            })
        }
    }


    return (
        <Dialog
            open={props.isOpen}
            initialFocus={completeButtonRef}
            onClose={() => props.setIsOpen(false)}
            className="relative z-50 "
            as="div"
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-[#15151566]" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center">
                {/* The actual dialog panel  */}

                <Dialog.Panel className="mx-auto lg:w-[35.3125rem] w-[95%] flex flex-col items-center bg-inherit rounded-[1.25rem] lg:p-[1.5rem]">
                    <div id="captureElement" className='bg-white w-[100%] rounded-[0.75rem] lg:p-[1.5rem] p-[0.7rem]'>
                        <div className='flex items-center justify-between mb-[0.5rem]'>
                            <p className='font-poppins font-semibold text-[#161616]'>Withdrawal Details</p>
                            <div className='flex items-center gap-[1rem]'>
                                <svg className='cursor-pointer' onClick={() => handleDownload()} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 16L16 11H13V4H11V11H8L12 16Z" fill="#161616" />
                                    <path d="M20 18H4V11H2V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V11H20V18Z" fill="#161616" />
                                </svg>
                                <svg className='cursor-pointer' onClick={() => window.print()} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z" fill="#161616" />
                                </svg>
                            </div>
                        </div>
                        <p className='font-poppins text-[0.875rem] text-[#16161699] mb-[0.5rem]'>Status: <span className={`${props.selectedWithdrawal.status === "pending" ? "text-[#E7A600]" : props.selectedWithdrawal.status === "declined" ? "text-[#B20F19]" : "text-[#018B28]"} text-[1rem] font-semibold`}>{props.selectedWithdrawal.status}</span></p>
                        <div className='flex items-center lg:gap-[2rem] gap-[1rem] border-b border-b-[#D8D8D8] pb-[0.5rem]'>
                            <CSVLink data={CSVdata} filename={"withdrawal_details_data.csv"}>
                                <p className='font-poppins whitespace-nowrap font-semibold text-[#161616] flex items-center gap-[0.62rem]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M13.75 7.3125V2H5.9375C5.41797 2 5 2.41797 5 2.9375V21.0625C5 21.582 5.41797 22 5.9375 22H19.0625C19.582 22 20 21.582 20 21.0625V8.25H14.6875C14.1719 8.25 13.75 7.82812 13.75 7.3125ZM10 12.9375C10 13.1102 9.86016 13.25 9.6875 13.25H9.375C9.02969 13.25 8.75 13.5297 8.75 13.875V15.125C8.75 15.4703 9.02969 15.75 9.375 15.75H9.6875C9.86016 15.75 10 15.8898 10 16.0625V16.6875C10 16.8602 9.86016 17 9.6875 17H9.375C8.33945 17 7.5 16.1605 7.5 15.125V13.875C7.5 12.8395 8.33945 12 9.375 12H9.6875C9.86016 12 10 12.1398 10 12.3125V12.9375ZM11.7293 17H11.25C11.0773 17 10.9375 16.8602 10.9375 16.6875V16.0625C10.9375 15.8898 11.0773 15.75 11.25 15.75H11.7293C11.9617 15.75 12.1359 15.6133 12.1359 15.4914C12.1359 15.4406 12.1066 15.3875 12.0531 15.3414L11.198 14.6082C10.8672 14.3262 10.6773 13.9254 10.6773 13.509C10.6773 12.677 11.4203 12.0004 12.334 12.0004H12.8125C12.9852 12.0004 13.125 12.1402 13.125 12.3129V12.9379C13.125 13.1105 12.9852 13.2504 12.8125 13.2504H12.3332C12.1008 13.2504 11.9266 13.3871 11.9266 13.509C11.9266 13.5598 11.9559 13.6129 12.0094 13.659L12.8645 14.3922C13.1953 14.6742 13.3852 15.075 13.3852 15.4914C13.3855 16.323 12.643 17 11.7293 17ZM15 12.3125V13.125C15 13.9168 15.2227 14.6941 15.625 15.3469C16.0273 14.6945 16.25 13.9168 16.25 13.125V12.3125C16.25 12.1398 16.3898 12 16.5625 12H17.1875C17.3602 12 17.5 12.1398 17.5 12.3125V13.125C17.5 14.5109 16.9969 15.816 16.0828 16.8004C15.9648 16.9273 15.7988 17 15.625 17C15.4512 17 15.2852 16.9273 15.1672 16.8004C14.2531 15.816 13.75 14.5109 13.75 13.125V12.3125C13.75 12.1398 13.8898 12 14.0625 12H14.6875C14.8602 12 15 12.1398 15 12.3125ZM19.7266 6.10156L15.9023 2.27344C15.7266 2.09766 15.4883 2 15.2383 2H15V7H20V6.76172C20 6.51562 19.9023 6.27734 19.7266 6.10156Z" fill="#161616" />
                                    </svg>
                                    Export to CSV
                                </p>
                            </CSVLink>
                            <p onClick={() => exportData(JSON.stringify(props.selectedWithdrawal), "withdrawal_details_data.json", "application/json")} className='cursor-pointer font-poppins whitespace-nowrap font-semibold text-[#161616] flex items-center gap-[0.62rem]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12.8228 15.1221C12.3058 15.1221 12.0068 15.6131 12.0068 16.2681C12.0068 16.9291 12.3178 17.3941 12.8268 17.3941C13.3438 17.3941 13.6388 16.9041 13.6388 16.2481C13.6388 15.6441 13.3478 15.1221 12.8228 15.1221Z" fill="#161616" />
                                    <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM8.022 16.704C8.022 17.665 7.561 18 6.822 18C6.646 18 6.416 17.971 6.265 17.92L6.351 17.305C6.455 17.34 6.59 17.365 6.742 17.365C7.061 17.365 7.262 17.22 7.262 16.695V14.573H8.023L8.022 16.704ZM9.481 17.995C9.096 17.995 8.715 17.895 8.526 17.79L8.681 17.159C8.885 17.264 9.202 17.37 9.527 17.37C9.877 17.37 10.061 17.224 10.061 17.005C10.061 16.794 9.902 16.674 9.497 16.529C8.935 16.334 8.57 16.023 8.57 15.533C8.57 14.957 9.051 14.516 9.847 14.516C10.227 14.516 10.506 14.596 10.708 14.687L10.536 15.302C10.401 15.237 10.161 15.142 9.831 15.142C9.501 15.142 9.34 15.292 9.34 15.467C9.34 15.682 9.53 15.778 9.967 15.943C10.563 16.163 10.843 16.473 10.843 16.949C10.844 17.515 10.407 17.995 9.481 17.995ZM12.787 18C11.786 18 11.201 17.245 11.201 16.284C11.201 15.272 11.847 14.516 12.843 14.516C13.878 14.516 14.444 15.292 14.444 16.223C14.443 17.33 13.773 18 12.787 18ZM17.734 17.945H16.932L16.211 16.643C15.9953 16.2568 15.8 15.8596 15.626 15.453L15.61 15.458C15.631 15.903 15.641 16.379 15.641 16.93V17.946H14.94V14.573H15.831L16.532 15.809C16.732 16.163 16.932 16.584 17.084 16.964H17.098C17.048 16.519 17.033 16.064 17.033 15.558V14.573H17.735V17.945H17.734ZM14 9H13V4L18 9H14Z" fill="#161616" />
                                </svg>
                                Export to JSON
                            </p>
                        </div>
                        <p className='flex items-center justify-between border-b border-b-[#D8D8D8] py-[0.5rem] font-poppins text-[0.875rem] text-[#16161699]'>
                            Withdrawal ID: <span className='text-[#161616] font-semibold text-[1rem]'>{props.selectedWithdrawal.withdrawalID}</span>
                        </p>
                        <p className='flex items-center justify-between border-b border-b-[#D8D8D8] py-[0.5rem] font-poppins text-[0.875rem] text-[#16161699]'>
                            Username: <span className='text-[#161616] font-semibold text-[1rem]'>{props.selectedWithdrawal.username}</span>
                        </p>
                        <p className='flex items-center justify-between border-b border-b-[#D8D8D8] py-[0.5rem] font-poppins text-[0.875rem] text-[#16161699]'>
                            User ID: <span className='text-[#161616] font-semibold text-[1rem]'>{props.selectedWithdrawal.userID}</span>
                        </p>
                        <p className='flex items-center justify-between border-b border-b-[#D8D8D8] py-[0.5rem] font-poppins text-[0.875rem] text-[#16161699]'>
                            Amount: <span className='text-[#161616] font-semibold text-[1rem]'>{props.selectedWithdrawal.amount}</span>
                        </p>
                        <p className='flex items-center justify-between border-b border-b-[#D8D8D8] py-[0.5rem] font-poppins text-[0.875rem] text-[#16161699]'>
                            Type: <span className='text-[#161616] font-semibold text-[1rem]'>{props.selectedWithdrawal.type} withdrawal request</span>
                        </p>
                        <p className='flex items-center justify-between border-b border-b-[#D8D8D8] py-[0.5rem] font-poppins text-[0.875rem] text-[#16161699]'>
                            Date: <span className='text-[#161616] font-semibold text-[1rem]'>{ConvertData(props.selectedWithdrawal.date)}</span>
                        </p>

                        {props.selectedWithdrawal.status === "completed" || props.selectedWithdrawal.status === "approved" ? <div className='py-[0.5rem]'><button onClick={FlagWithdrawal} className={`w-[100%] font-poppins font-semibold ${props.selectedWithdrawal.flag ? "text-[#0FB23C] border border-[#0FB23C]" : "text-[#B20F19] border border-[#B20F19]"} rounded-[0.75rem] px-[2rem] py-[0.75rem] whitespace-nowrap`}>{flagLoading ? "Please wait..." : props.selectedWithdrawal.flag ? "Unflag Withdrawal" : "Flag Withdrawal"}</button></div> : <div className='py-[0.5rem] flex lg:flex-row flex-col items-center gap-[1rem]'>
                            <button onClick={() => {
                                props.setIsOpen(false)
                                props.setOpenReject(true)
                            }} className='lg:w-[50%] w-[100%] font-poppins font-semibold text-[#B20F19] border border-[#B20F19] rounded-[0.75rem] px-[2rem] py-[0.75rem] whitespace-nowrap'>Decline</button>
                            <button disabled={loading} onClick={() => {
                                // handleApprove()
                                props.setIdType({
                                    id: props.id,
                                    type: props.type
                                })
                                props.CheckBalance(props.selectedWithdrawal.withdrawalID, props.selectedWithdrawal.type, props.selectedWithdrawal.bank_name, props.selectedWithdrawal.account_number, props.selectedWithdrawal.amount)
                                // props.getBankCode(props.selectedWithdrawal.bank_name, props.selectedWithdrawal.account_number, props.selectedWithdrawal.amount)
                                props.setIsOpen(false)

                            }} className='lg:w-[50%] w-[100%] font-poppins font-semibold text-white border bg-[#0FB23C] border-[#0FB23C] rounded-[0.75rem] px-[2rem] py-[0.75rem] whitespace-nowrap'>Approve</button>
                        </div>
                        }
                        <p className='text-center font-poppins text-[#161616] text-[0.875rem]'>For any additional request, <Link to={`/users?id=${props.selectedWithdrawal.userID}`}><span className='text-[#0FB23C] font-semibold'>contact the user</span></Link></p>

                        <p
                            ref={completeButtonRef}
                            onClick={() => props.setIsOpen(false)}
                            className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
                        >
                        </p>

                    </div>
                    <div onClick={() => props.setIsOpen(!props.isOpen)} className='bg-white cursor-pointer rounded-full w-[3.125rem] h-[3.125rem] flex items-center justify-center mt-[2rem]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M18.8002 5.70998C18.7077 5.61728 18.5978 5.54373 18.4768 5.49355C18.3559 5.44337 18.2262 5.41754 18.0952 5.41754C17.9643 5.41754 17.8346 5.44337 17.7136 5.49355C17.5926 5.54373 17.4827 5.61728 17.3902 5.70998L12.5002 10.59L7.61022 5.69998C7.51764 5.6074 7.40773 5.53396 7.28677 5.48385C7.1658 5.43375 7.03615 5.40796 6.90522 5.40796C6.77429 5.40796 6.64464 5.43375 6.52368 5.48385C6.40272 5.53396 6.29281 5.6074 6.20022 5.69998C6.10764 5.79256 6.0342 5.90247 5.9841 6.02344C5.93399 6.1444 5.9082 6.27405 5.9082 6.40498C5.9082 6.53591 5.93399 6.66556 5.9841 6.78652C6.0342 6.90749 6.10764 7.0174 6.20022 7.10998L11.0902 12L6.20022 16.89C6.10764 16.9826 6.0342 17.0925 5.9841 17.2134C5.93399 17.3344 5.9082 17.464 5.9082 17.595C5.9082 17.7259 5.93399 17.8556 5.9841 17.9765C6.0342 18.0975 6.10764 18.2074 6.20022 18.3C6.29281 18.3926 6.40272 18.466 6.52368 18.5161C6.64464 18.5662 6.77429 18.592 6.90522 18.592C7.03615 18.592 7.1658 18.5662 7.28677 18.5161C7.40773 18.466 7.51764 18.3926 7.61022 18.3L12.5002 13.41L17.3902 18.3C17.4828 18.3926 17.5927 18.466 17.7137 18.5161C17.8346 18.5662 17.9643 18.592 18.0952 18.592C18.2262 18.592 18.3558 18.5662 18.4768 18.5161C18.5977 18.466 18.7076 18.3926 18.8002 18.3C18.8928 18.2074 18.9662 18.0975 19.0163 17.9765C19.0665 17.8556 19.0922 17.7259 19.0922 17.595C19.0922 17.464 19.0665 17.3344 19.0163 17.2134C18.9662 17.0925 18.8928 16.9826 18.8002 16.89L13.9102 12L18.8002 7.10998C19.1802 6.72998 19.1802 6.08998 18.8002 5.70998Z" fill="#161616" />
                        </svg>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>

    )
}

export default WithdrawalManagement