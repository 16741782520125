import React, { useEffect, useState } from "react";
import MainTableStructure from "../Tables/MainTableStructure";
import UserActivityTable from "../Tables/UserActivityTable";
import { useLocation } from "react-router-dom";
import UserAssetsBreakdownTable from "../Tables/UserAssetsBreakdownTable";
import SingleUserActivityTable from "../Tables/SingleUserActivityTable";
import UserTradesHistoryTable from "../Tables/UserTradesHistoryTable";
import UserPaymentHistoryTable from "../Tables/UserPaymentHistoryTable";
import axios from "../../axios";
import ViewID from "../Modals/ViewID";
import ViewAddress from "../Modals/ViewAddress";

const usersHeaders = [
  {
    key: "user-id",
    label: "User ID",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "email",
    label: "Email Address",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "last-login",
    label: "Last Login",
  },
  {
    key: "creation-date",
    label: "Creation Date",
  },
];

const usersActivityHeader = [
  {
    key: "timestamp",
    label: "Timestamp",
  },
  {
    key: "username",
    label: "Username",
  },
  {
    key: "action",
    label: "Action Type",
  },
  {
    key: "device_type",
    label: "Device Type",
  },
  // {
  //   key: "description",
  //   label: "Description",
  // },
  // {
  //   key: "ip",
  //   label: "IP Address",
  // },
];

const userAssetsHeaders = [
  {
    key: "symbol",
    label: "Symbol",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "amount",
    label: "Amount",
  },
  {
    key: "value",
    label: "Value(#)",
  },
  {
    key: "days",
    label: "7 Days",
  },
];

const userTradeHistoryHeaders = [
  {
    key: "tradeID",
    label: "Trade ID",
  },
  {
    key: "symbol",
    label: "Symbol",
  },
  {
    key: "order_type",
    label: "Order Type",
  },
  {
    key: "quantity",
    label: "Quantity",
  },
  {
    key: "price",
    label: "Price",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "execution_time",
    label: "Execution Time",
  },
];

const userPaymentHistoryHeaders = [
  {
    key: "receiptID",
    label: "Receipt ID",
  },
  {
    key: "payment_method",
    label: "Payment Method",
  },
  {
    key: "amount",
    label: "Amount",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "date_submitted",
    label: "Date Submitted",
  },
];

const convertDate = (dateString) => {
  const dateObject = new Date(dateString);
  const readableDate = dateObject.toLocaleString();
  return readableDate;
};

function Users() {
  const [page, setPage] = useState(0);
  const [profilePage, setProfilePage] = useState(0);
  const params = useLocation();
  const [userData, setUserData] = useState([]);
  const [userOtherData, setUserOtherData] = useState();
  const [activityOtherData, setActivityOtherData] = useState();
  const [usersActivityLogs, setUserActivitiesLogs] = useState([]);
  const [userActivityLogs, setUsersActivitiesLogs] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [flat, setFlat] = useState(0);
  const [noOfStock, setNoOfStock] = useState(0);
  const [totalStockVal, setTotalStockVal] = useState(0);
  const [stocks, setStocks] = useState([]);
  const [userTradeHistory, setUserTradeHistory] = useState([]);
  const [userPaymentHistory, setUserPaymentHistory] = useState([]);
  // const userId = params.search.split("=")[1]
  const userIdAvailable = params.search.split("=")[0];
  const userId = params.search.split("=")[1];
  const [refresh, setRefresh] = useState(false);
  const [loadingDeactivate, setLoadingDeactivate] = useState(false);
  const [openIdModal, setOpenIDModal] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  // console.log(params.search.split("=")[1])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        axios
          .get("new/manage-users/")
          .then((res) => {
            console.log(res);
            setUserOtherData({
              count: res.data.count,
              next: res.data.next,
              prev: res.data.previous,
            });
            setUserData(res.data.results);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {}
    };

    const fetchSingleUser = async () => {
      try {
        axios
          .get(`new/manage-users/${userId}`)
          .then((res) => {
            console.log(res);
            setUserDetail(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {}
    };

    // eslint-disable-next-line no-unused-vars
    const fetchUserAssetsBreakdown = async () => {
      try {
        axios
          .get(`user-asset-breakdown/${userId}/`)
          .then((res) => {
            console.log(res);
            setFlat(res.data.acc_summary.fiat_acc_balance);
            setNoOfStock(res.data.acc_summary.no_of_stocks_owned);
            setTotalStockVal(res.data.acc_summary.total_val_of_stocks_owned);
            setStocks(res.data.stock_history);
            // setUserDetail(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {}
    };

    const fetchUserActivityLogs = async () => {
      try {
        axios
          .get(`users-activity-log/${userId}/`)
          .then((res) => {
            console.log(res);
            setUsersActivitiesLogs(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {}
    };

    const fetchUserTradeActivityLogs = async () => {
      try {
        axios
          .get(`trade-activity-log/${userId}/`)
          .then((res) => {
            console.log(res);
            setUserTradeHistory(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {}
    };

    const fetchUserPaymentHistory = async () => {
      try {
        axios
          .get(`user-payment-history/${userId}/`)
          .then((res) => {
            console.log(res);
            setUserPaymentHistory(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {}
    };

    const fetchUsersActivityLogs = async () => {
      try {
        axios
          .get(`new/users-activity-log/`)
          .then((res) => {
            console.log(res);
            setActivityOtherData({
              count: res.data.count,
              next: res.data.next,
              prev: res.data.previous,
            });
            setUserActivitiesLogs(res.data.results);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {}
    };

    fetchUserPaymentHistory();
    fetchUserTradeActivityLogs();
    fetchUserActivityLogs();
    // fetchUserAssetsBreakdown();
    fetchUsersActivityLogs();
    fetchSingleUser();
    fetchUsers();
  }, [userId, refresh]);

  const DeactivateActivateUser = async (id, type) => {
    setLoadingDeactivate(true);
    axios
      .post(`activate-deactivate-account/`, { user_id: id, keyword: type })
      .then((res) => {
        console.log(res);
        setLoadingDeactivate(false);
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
        alert("Failed to activate/deactivate user");
        setLoadingDeactivate(false);
      });
  };

  return (
    <div className="pb-[2rem]">
      {!userIdAvailable && (
        <div className="lg:px-[2rem] px-[1rem]">
          <div className="flex border-t border-t-[#D8D8D8] max-w-max">
            <p
              onClick={() => setPage(0)}
              className={`px-[1rem] cursor-pointer py-[0.75rem] rounded-bl-[0.75rem] ${
                page === 0
                  ? "bg-[#0FB23C] text-white"
                  : "bg-white text-[#16161699]"
              } font-poppins text-[0.875rem]`}
            >
              User Management
            </p>
            <p
              onClick={() => setPage(1)}
              className={`px-[1rem] cursor-pointer py-[0.75rem] rounded-br-[0.75rem] ${
                page === 1
                  ? "bg-[#0FB23C] text-white"
                  : "bg-white text-[#16161699]"
              } font-poppins text-[0.875rem]`}
            >
              Users Activity Log
            </p>
          </div>
          <div className="p-[0.62rem] rounded-[0.75rem] bg-[#0FB23C1A] font-poppins mt-[2rem]">
            <h1 className="flex items-center gap-[0.62rem] font-semibold text-[0.875rem] text-[#018B28]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clip-path="url(#clip0_1652_8242)">
                  <path
                    d="M8.6665 5.00016C8.6665 5.17697 8.59627 5.34654 8.47124 5.47157C8.34622 5.59659 8.17665 5.66683 7.99984 5.66683C7.82303 5.66683 7.65346 5.59659 7.52843 5.47157C7.40341 5.34654 7.33317 5.17697 7.33317 5.00016C7.33317 4.82335 7.40341 4.65378 7.52843 4.52876C7.65346 4.40373 7.82303 4.3335 7.99984 4.3335C8.17665 4.3335 8.34622 4.40373 8.47124 4.52876C8.59627 4.65378 8.6665 4.82335 8.6665 5.00016ZM6.6665 7.50016C6.6665 7.36755 6.71918 7.24038 6.81295 7.14661C6.90672 7.05284 7.0339 7.00016 7.1665 7.00016H8.1665C8.29911 7.00016 8.42629 7.05284 8.52006 7.14661C8.61383 7.24038 8.6665 7.36755 8.6665 7.50016V10.3335H9.1665C9.29911 10.3335 9.42629 10.3862 9.52006 10.4799C9.61383 10.5737 9.6665 10.7009 9.6665 10.8335C9.6665 10.9661 9.61383 11.0933 9.52006 11.187C9.42629 11.2808 9.29911 11.3335 9.1665 11.3335H7.1665C7.0339 11.3335 6.90672 11.2808 6.81295 11.187C6.71918 11.0933 6.6665 10.9661 6.6665 10.8335C6.6665 10.7009 6.71918 10.5737 6.81295 10.4799C6.90672 10.3862 7.0339 10.3335 7.1665 10.3335H7.6665V8.00016H7.1665C7.0339 8.00016 6.90672 7.94748 6.81295 7.85372C6.71918 7.75995 6.6665 7.63277 6.6665 7.50016Z"
                    fill="#018B28"
                  />
                  <path
                    d="M7.99984 0.666504C12.0498 0.666504 15.3332 3.94984 15.3332 7.99984C15.3332 12.0498 12.0498 15.3332 7.99984 15.3332C3.94984 15.3332 0.666504 12.0498 0.666504 7.99984C0.666504 3.94984 3.94984 0.666504 7.99984 0.666504ZM1.6665 7.99984C1.6665 9.67954 2.33376 11.2905 3.52149 12.4782C4.70922 13.6659 6.32013 14.3332 7.99984 14.3332C9.67954 14.3332 11.2905 13.6659 12.4782 12.4782C13.6659 11.2905 14.3332 9.67954 14.3332 7.99984C14.3332 6.32013 13.6659 4.70922 12.4782 3.52149C11.2905 2.33376 9.67954 1.6665 7.99984 1.6665C6.32013 1.6665 4.70922 2.33376 3.52149 3.52149C2.33376 4.70922 1.6665 6.32013 1.6665 7.99984Z"
                    fill="#018B28"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1652_8242">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              INSTRUCTIONS
            </h1>
            <p className="text-[0.875rem] text-[#161616] mt-[0.62rem]">
              To sort any of the tabs click on the tab heading, and click again
              to reverse the data. Select a user by clicking on the Username to
              view full account details.
            </p>
          </div>
          <div>
            {page === 0 && (
              <MainTableStructure
                head={usersHeaders}
                body={userData}
                DeactivateActivateUser={DeactivateActivateUser}
                otherData={userOtherData}
                setUserOtherData={setUserOtherData}
                setUserData={setUserData}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            )}
            {page === 1 && (
              <UserActivityTable
                head={usersActivityHeader}
                body={usersActivityLogs}
                users={userData}
                otherData={activityOtherData}
                setUserOtherData={setActivityOtherData}
                setUserData={setUserActivitiesLogs}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            )}
          </div>
        </div>
      )}

      {userIdAvailable && (
        <div className="lg:px-[2rem] font-poppins">
          <div className="flex overflow-x-scroll no-scrollbar w-[100%] border-t border-t-[#D8D8D8] max-w-max">
            <p
              onClick={() => setProfilePage(0)}
              className={`px-[1rem] whitespace-nowrap cursor-pointer py-[0.75rem] rounded-bl-[0.75rem] ${
                profilePage === 0
                  ? "bg-[#0FB23C] text-white"
                  : "bg-white text-[#16161699]"
              } font-poppins text-[0.875rem]`}
            >
              User Profile Details
            </p>
            {/* <p onClick={() => setProfilePage(1)} className={`px-[1rem] whitespace-nowrap cursor-pointer py-[0.75rem] border-x ${profilePage === 1 ? "bg-[#0FB23C] text-white" : "bg-white text-[#16161699]"} font-poppins text-[0.875rem]`}>User Assets Breakdown</p> */}
            <p
              onClick={() => setProfilePage(2)}
              className={`px-[1rem] whitespace-nowrap cursor-pointer py-[0.75rem] border-x ${
                profilePage === 2
                  ? "bg-[#0FB23C] text-white"
                  : "bg-white text-[#16161699]"
              } font-poppins text-[0.875rem]`}
            >
              Users Activity Log
            </p>
            <p
              onClick={() => setProfilePage(3)}
              className={`px-[1rem] whitespace-nowrap cursor-pointer py-[0.75rem] border-x ${
                profilePage === 3
                  ? "bg-[#0FB23C] text-white"
                  : "bg-white text-[#16161699]"
              } font-poppins text-[0.875rem]`}
            >
              Users Trade History
            </p>
            <p
              onClick={() => setProfilePage(4)}
              className={`px-[1rem] whitespace-nowrap cursor-pointer py-[0.75rem] rounded-br-[0.75rem] ${
                profilePage === 4
                  ? "bg-[#0FB23C] text-white"
                  : "bg-white text-[#16161699]"
              } font-poppins text-[0.875rem]`}
            >
              Users Payment History
            </p>
          </div>

          <div className="p-[1rem] lg:p-0">
            <p className="text-[0.625rem] text-[#16161699] my-[2rem]">
              <span className="underline text-[#000]">User Management</span>{" "}
              <span className="text-[#000]">/</span> Account Management
            </p>
            {profilePage === 0 && (
              <div className="bg-white flex lg:flex-row flex-col p-[1.5rem] rounded-[0.75rem] shadow-[0_2px_16px_0_rgba(128,128,128,0.18)]">
                <div className="lg:border-r-[0.08rem] lg:border-r-[#D8D8D8] lg:pr-[2rem]">
                  <div className="border-b-[0.08rem] border-b-[#D8D8D8] flex flex-col items-center">
                    <img
                      src={userDetail.profile_image}
                      alt=""
                      className="w-[9.5625rem] h-[9.5625rem] mb-[1rem]"
                    />
                    <h1 className="mb-[1rem] text-[1.5rem] font-medium text-[#161616]">
                      {userDetail.lastname}, {userDetail.firstname}
                    </h1>
                    <p className="bg-[#F1F1F1] px-[1rem] py-[0.25rem] rounded-full text-[0.875rem] text-[#161616] mb-[1rem]">
                      User ID: {userDetail.id}
                    </p>
                    <p
                      className={`text-[0.875rem] font-semibold ${
                        userDetail.is_active
                          ? "text-[#0FB23C]"
                          : "text-[#B20F19]"
                      } mb-[1rem]`}
                    >
                      {userDetail.is_active ? "ACTIVE" : "INACTIVE"}
                    </p>
                  </div>
                  <div className="flex flex-col pt-[1rem] gap-[1rem] lg:border-none lg:pb-0 border-b-[0.08rem] border-b-[#D8D8D8] pb-[1rem]">
                    {userDetail.is_active ? (
                      <button
                        disabled={loadingDeactivate}
                        onClick={() =>
                          DeactivateActivateUser(userDetail.id, "deactivate")
                        }
                        className="px-[2rem] py-[0.75rem] border border-[#B20F19] rounded-[0.75rem] text-[1rem] font-semibold text-[#B20F19]"
                      >
                        {loadingDeactivate
                          ? "Please wait..."
                          : "Deactivate Account"}
                      </button>
                    ) : (
                      <button
                        disabled={loadingDeactivate}
                        onClick={() =>
                          DeactivateActivateUser(userDetail.id, "activate")
                        }
                        className="px-[2rem] py-[0.75rem] border border-[#0FB23C] rounded-[0.75rem] text-[1rem] font-semibold text-[#0FB23C]"
                      >
                        {loadingDeactivate
                          ? "Please wait.."
                          : "Activate Account"}
                      </button>
                    )}

                    <button
                      disabled={loadingDeactivate}
                      onClick={() => setOpenIDModal(true)}
                      className="px-[2rem] py-[0.75rem] border border-black rounded-[0.75rem] text-[1rem] font-semibold text-black"
                    >
                      View ID
                    </button>
                    <button
                      disabled={loadingDeactivate}
                      onClick={() => setOpenAddressModal(true)}
                      className="px-[2rem] py-[0.75rem] border border-black rounded-[0.75rem] text-[1rem] font-semibold text-black"
                    >
                      View Proof of Adress
                    </button>
                  </div>
                </div>
                <div className="flex flex-col gap-[2rem] mt-[1rem] lg:mt-0 lg:pl-[2rem]">
                  <p className="text-[0.875rem] text-[#16161699] flex items-center gap-[0.5rem] justify-between lg:justify-normal">
                    First Name{" "}
                    <span className="text-[1rem] font-semibold text-[#161616]">
                      {userDetail.firstname}
                    </span>
                  </p>
                  <p className="text-[0.875rem] text-[#16161699] flex items-center gap-[0.5rem] justify-between lg:justify-normal">
                    Last Name{" "}
                    <span className="text-[1rem] font-semibold text-[#161616]">
                      {userDetail.lastname}
                    </span>
                  </p>
                  <p className="text-[0.875rem] text-[#16161699] flex items-center gap-[0.5rem] justify-between lg:justify-normal">
                    Email Address{" "}
                    <span className="text-[1rem] font-semibold text-[#161616]">
                      {userDetail.email}
                    </span>
                  </p>
                  <p className="text-[0.875rem] text-[#16161699] flex items-center gap-[0.5rem] justify-between lg:justify-normal">
                    Phone Number{" "}
                    <span className="text-[1rem] font-semibold text-[#161616]">
                      {userDetail.profile_phone
                        ? userDetail.profile_phone
                        : "Null"}
                    </span>
                  </p>
                  <p className="text-[0.875rem] text-[#16161699] flex items-center gap-[0.5rem] justify-between lg:justify-normal">
                    Last Login{" "}
                    <span className="text-[1rem] font-semibold text-[#161616]">
                      {userDetail.last_login
                        ? convertDate(userDetail.last_login)
                        : "Null"}
                    </span>
                  </p>
                  <p className="text-[0.875rem] text-[#16161699] flex items-center gap-[0.5rem] justify-between lg:justify-normal">
                    Date Created{" "}
                    <span className="text-[1rem] font-semibold text-[#161616]">
                      {convertDate(userDetail.date_joined)}
                    </span>
                  </p>
                  <p className="text-[0.875rem] text-[#16161699] flex items-center gap-[0.5rem] justify-between lg:justify-normal">
                    Number of People Referred{" "}
                    <span className="text-[1rem] font-semibold text-[#161616]">
                      {userDetail.no_of_referred_users
                        ? userDetail.no_of_referred_users
                        : "Null"}
                    </span>
                  </p>
                  <p className="text-[0.875rem] text-[#16161699] flex items-center gap-[0.5rem] justify-between lg:justify-normal">
                    Bank Name{" "}
                    <span className="text-[1rem] font-semibold text-[#161616]">
                      {userDetail.bank_name ? userDetail.bank_name : "Null"}
                    </span>
                  </p>
                  <p className="text-[0.875rem] text-[#16161699] flex items-center gap-[0.5rem] justify-between lg:justify-normal">
                    Account Number{" "}
                    <span className="text-[1rem] font-semibold text-[#161616]">
                      {userDetail.account_number
                        ? userDetail.account_number
                        : "Null"}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {profilePage === 1 && (
              <div>
                <div className="bg-white lg:p-[1.5rem] p-[1rem] lg:w-[562px]  rounded-[0.75rem] shadow-[0_2px_16px_0_rgba(128,128,128,0.18)]">
                  <p className="flex items-center gap-[0.5rem] text-[0.875rem] text-[#16161699] border-b pb-[2rem]">
                    Fiat Account Balance:{" "}
                    <span className="flex text-[1rem] text-[#161616] font-semibold items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M4.66634 12V4.70133C4.66629 4.54622 4.71777 4.39548 4.81269 4.27279C4.9076 4.1501 5.04058 4.06242 5.19074 4.0235C5.3409 3.98459 5.49973 3.99666 5.64228 4.0578C5.78484 4.11895 5.90305 4.22572 5.97834 4.36133L10.021 11.6387C10.0963 11.7743 10.2145 11.881 10.3571 11.9422C10.4996 12.0033 10.6585 12.0154 10.8086 11.9765C10.9588 11.9376 11.0917 11.8499 11.1867 11.7272C11.2816 11.6045 11.3331 11.4538 11.333 11.2987V4M3.33301 6.66667H12.6663M3.33301 9.33333H12.6663"
                          stroke="#161616"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {flat.toLocaleString()}
                    </span>
                  </p>
                  <p className="flex items-center text-[0.875rem] text-[#16161699] gap-[0.5rem] border-y py-[2rem]">
                    No. of Stocks Owned:{" "}
                    <span className="text-[1rem] text-[#161616] font-semibold">
                      {noOfStock.toLocaleString()}
                    </span>
                  </p>
                  <div className="flex lg:flex-row flex-col gap-[1rem] lg:items-center justify-between mt-[2rem] bg-[#0FB23C1A] p-[0.5rem] rounded-[0.75rem]">
                    <p className="flex items-center text-[0.875rem] text-[#16161699] gap-[0.5rem]">
                      Total Value of Stocks:{" "}
                      <span className="flex items-center text-[1rem] text-[#161616] font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M4.66634 12V4.70133C4.66629 4.54622 4.71777 4.39548 4.81269 4.27279C4.9076 4.1501 5.04058 4.06242 5.19074 4.0235C5.3409 3.98459 5.49973 3.99666 5.64228 4.0578C5.78484 4.11895 5.90305 4.22572 5.97834 4.36133L10.021 11.6387C10.0963 11.7743 10.2145 11.881 10.3571 11.9422C10.4996 12.0033 10.6585 12.0154 10.8086 11.9765C10.9588 11.9376 11.0917 11.8499 11.1867 11.7272C11.2816 11.6045 11.3331 11.4538 11.333 11.2987V4M3.33301 6.66667H12.6663M3.33301 9.33333H12.6663"
                            stroke="#161616"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        {totalStockVal.toLocaleString()}
                      </span>
                    </p>
                    <div className="flex items-center gap-[0.5rem]">
                      <p className="flex items-center gap-[0.62rem] text-[#0FB23C]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.6669 4.66V7.33333C14.6669 7.46517 14.6278 7.59403 14.5545 7.70364C14.4813 7.81325 14.3772 7.89867 14.2554 7.94912C14.1336 7.99957 13.9996 8.01277 13.8702 7.98706C13.7409 7.96134 13.6222 7.89787 13.5289 7.80467L12.6669 6.94267L9.13827 10.4713C9.01325 10.5963 8.84371 10.6665 8.66694 10.6665C8.49016 10.6665 8.32062 10.5963 8.19561 10.4713L6.00027 8.276L2.4716 11.8047C2.34587 11.9261 2.17747 11.9933 2.00267 11.9918C1.82787 11.9903 1.66066 11.9202 1.53706 11.7965C1.41345 11.6729 1.34334 11.5057 1.34182 11.3309C1.3403 11.1561 1.4075 10.9877 1.52894 10.862L5.52894 6.862C5.65396 6.73702 5.8235 6.66681 6.00027 6.66681C6.17705 6.66681 6.34659 6.73702 6.4716 6.862L8.66694 9.05733L11.7243 6L10.8623 5.138C10.7691 5.04477 10.7056 4.92599 10.6799 4.79669C10.6542 4.66739 10.6674 4.53336 10.7178 4.41156C10.7683 4.28976 10.8537 4.18565 10.9633 4.1124C11.0729 4.03914 11.2018 4.00003 11.3336 4H14.0003L14.0323 4.00067C14.2026 4.00819 14.3634 4.08092 14.4816 4.20378C14.5997 4.32663 14.6661 4.49022 14.6669 4.66067V4.66Z"
                            fill="#0FB23C"
                          />
                        </svg>
                        4.76%
                      </p>
                      <p className="text-[#16161699]">7 Days</p>
                    </div>
                  </div>
                </div>
                <UserAssetsBreakdownTable
                  head={userAssetsHeaders}
                  body={stocks}
                />
              </div>
            )}
            {profilePage === 2 && (
              <SingleUserActivityTable
                head={usersActivityHeader}
                body={userActivityLogs}
                firstname={userDetail.firstname}
                lastname={userDetail.lastname}
              />
            )}
            {profilePage === 3 && (
              <div>
                <div className="p-[0.62rem] rounded-[0.75rem] bg-[#0FB23C1A] font-poppins mt-[2rem]">
                  <h1 className="flex items-center gap-[0.62rem] font-semibold text-[0.875rem] text-[#018B28]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1652_8242)">
                        <path
                          d="M8.6665 5.00016C8.6665 5.17697 8.59627 5.34654 8.47124 5.47157C8.34622 5.59659 8.17665 5.66683 7.99984 5.66683C7.82303 5.66683 7.65346 5.59659 7.52843 5.47157C7.40341 5.34654 7.33317 5.17697 7.33317 5.00016C7.33317 4.82335 7.40341 4.65378 7.52843 4.52876C7.65346 4.40373 7.82303 4.3335 7.99984 4.3335C8.17665 4.3335 8.34622 4.40373 8.47124 4.52876C8.59627 4.65378 8.6665 4.82335 8.6665 5.00016ZM6.6665 7.50016C6.6665 7.36755 6.71918 7.24038 6.81295 7.14661C6.90672 7.05284 7.0339 7.00016 7.1665 7.00016H8.1665C8.29911 7.00016 8.42629 7.05284 8.52006 7.14661C8.61383 7.24038 8.6665 7.36755 8.6665 7.50016V10.3335H9.1665C9.29911 10.3335 9.42629 10.3862 9.52006 10.4799C9.61383 10.5737 9.6665 10.7009 9.6665 10.8335C9.6665 10.9661 9.61383 11.0933 9.52006 11.187C9.42629 11.2808 9.29911 11.3335 9.1665 11.3335H7.1665C7.0339 11.3335 6.90672 11.2808 6.81295 11.187C6.71918 11.0933 6.6665 10.9661 6.6665 10.8335C6.6665 10.7009 6.71918 10.5737 6.81295 10.4799C6.90672 10.3862 7.0339 10.3335 7.1665 10.3335H7.6665V8.00016H7.1665C7.0339 8.00016 6.90672 7.94748 6.81295 7.85372C6.71918 7.75995 6.6665 7.63277 6.6665 7.50016Z"
                          fill="#018B28"
                        />
                        <path
                          d="M7.99984 0.666504C12.0498 0.666504 15.3332 3.94984 15.3332 7.99984C15.3332 12.0498 12.0498 15.3332 7.99984 15.3332C3.94984 15.3332 0.666504 12.0498 0.666504 7.99984C0.666504 3.94984 3.94984 0.666504 7.99984 0.666504ZM1.6665 7.99984C1.6665 9.67954 2.33376 11.2905 3.52149 12.4782C4.70922 13.6659 6.32013 14.3332 7.99984 14.3332C9.67954 14.3332 11.2905 13.6659 12.4782 12.4782C13.6659 11.2905 14.3332 9.67954 14.3332 7.99984C14.3332 6.32013 13.6659 4.70922 12.4782 3.52149C11.2905 2.33376 9.67954 1.6665 7.99984 1.6665C6.32013 1.6665 4.70922 2.33376 3.52149 3.52149C2.33376 4.70922 1.6665 6.32013 1.6665 7.99984Z"
                          fill="#018B28"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1652_8242">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    INSTRUCTIONS
                  </h1>
                  <p className="text-[0.875rem] text-[#161616] mt-[0.62rem]">
                    To sort any of the tabs click on the tab heading, and click
                    again to reverse the data. View more trade details by
                    clicking on Trade ID.
                  </p>
                </div>
                <UserTradesHistoryTable
                  head={userTradeHistoryHeaders}
                  body={userTradeHistory}
                />
              </div>
            )}
            {profilePage === 4 && (
              <div>
                <div className="p-[0.62rem] rounded-[0.75rem] bg-[#0FB23C1A] font-poppins mt-[2rem]">
                  <h1 className="flex items-center gap-[0.62rem] font-semibold text-[0.875rem] text-[#018B28]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1652_8242)">
                        <path
                          d="M8.6665 5.00016C8.6665 5.17697 8.59627 5.34654 8.47124 5.47157C8.34622 5.59659 8.17665 5.66683 7.99984 5.66683C7.82303 5.66683 7.65346 5.59659 7.52843 5.47157C7.40341 5.34654 7.33317 5.17697 7.33317 5.00016C7.33317 4.82335 7.40341 4.65378 7.52843 4.52876C7.65346 4.40373 7.82303 4.3335 7.99984 4.3335C8.17665 4.3335 8.34622 4.40373 8.47124 4.52876C8.59627 4.65378 8.6665 4.82335 8.6665 5.00016ZM6.6665 7.50016C6.6665 7.36755 6.71918 7.24038 6.81295 7.14661C6.90672 7.05284 7.0339 7.00016 7.1665 7.00016H8.1665C8.29911 7.00016 8.42629 7.05284 8.52006 7.14661C8.61383 7.24038 8.6665 7.36755 8.6665 7.50016V10.3335H9.1665C9.29911 10.3335 9.42629 10.3862 9.52006 10.4799C9.61383 10.5737 9.6665 10.7009 9.6665 10.8335C9.6665 10.9661 9.61383 11.0933 9.52006 11.187C9.42629 11.2808 9.29911 11.3335 9.1665 11.3335H7.1665C7.0339 11.3335 6.90672 11.2808 6.81295 11.187C6.71918 11.0933 6.6665 10.9661 6.6665 10.8335C6.6665 10.7009 6.71918 10.5737 6.81295 10.4799C6.90672 10.3862 7.0339 10.3335 7.1665 10.3335H7.6665V8.00016H7.1665C7.0339 8.00016 6.90672 7.94748 6.81295 7.85372C6.71918 7.75995 6.6665 7.63277 6.6665 7.50016Z"
                          fill="#018B28"
                        />
                        <path
                          d="M7.99984 0.666504C12.0498 0.666504 15.3332 3.94984 15.3332 7.99984C15.3332 12.0498 12.0498 15.3332 7.99984 15.3332C3.94984 15.3332 0.666504 12.0498 0.666504 7.99984C0.666504 3.94984 3.94984 0.666504 7.99984 0.666504ZM1.6665 7.99984C1.6665 9.67954 2.33376 11.2905 3.52149 12.4782C4.70922 13.6659 6.32013 14.3332 7.99984 14.3332C9.67954 14.3332 11.2905 13.6659 12.4782 12.4782C13.6659 11.2905 14.3332 9.67954 14.3332 7.99984C14.3332 6.32013 13.6659 4.70922 12.4782 3.52149C11.2905 2.33376 9.67954 1.6665 7.99984 1.6665C6.32013 1.6665 4.70922 2.33376 3.52149 3.52149C2.33376 4.70922 1.6665 6.32013 1.6665 7.99984Z"
                          fill="#018B28"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1652_8242">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    INSTRUCTIONS
                  </h1>
                  <p className="text-[0.875rem] text-[#161616] mt-[0.62rem]">
                    To sort any of the tabs click on the tab heading, and click
                    again to reverse the data. View more receipt details by
                    clicking on Receipt ID.
                  </p>
                </div>
                <UserPaymentHistoryTable
                  head={userPaymentHistoryHeaders}
                  body={userPaymentHistory}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <ViewID
        idImg={userDetail.proof_of_id}
        isOpen={openIdModal}
        setIsOpen={setOpenIDModal}
      />
      <ViewAddress
        addressImg={userDetail.proof_of_address}
        isOpen={openAddressModal}
        setIsOpen={setOpenAddressModal}
      />
    </div>
  );
}

export default Users;
