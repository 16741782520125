import React from 'react'


function MostTradedStockTable({data}) {
    console.log(data)
    return (
        <div className='w-[100%] overflow-x-scroll no-scrollbar'>
            <table className="w-[100%] border-collapse bg-white rounded-[1.25rem] ">
                <thead className="rounded-[1.25rem]">
                    <tr className="w-[100%]">
                        {/* {headers.map((row) => (
                            <td
                                className={`text-left border-b border-[#80808080] whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem] font-semibold`}
                                key={row.key}
                            >
                                {row.label}
                            </td>
                        ))} */}
                        <td
                            className={`text-left border-b border-[#80808080] whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}

                        >
                            Name
                        </td>
                        <td
                            className={`border-b border-[#80808080]  py-[1rem]`}

                        >
                            <svg className='ml-5' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M4.66683 12V4.70133C4.66678 4.54622 4.71826 4.39548 4.81318 4.27279C4.90809 4.1501 5.04107 4.06242 5.19123 4.0235C5.34138 3.98459 5.50021 3.99666 5.64277 4.0578C5.78533 4.11895 5.90353 4.22572 5.97883 4.36133L10.0215 11.6387C10.0968 11.7743 10.215 11.881 10.3576 11.9422C10.5001 12.0033 10.6589 12.0154 10.8091 11.9765C10.9593 11.9376 11.0922 11.8499 11.1871 11.7272C11.2821 11.6045 11.3335 11.4538 11.3335 11.2987V4M3.3335 6.66667H12.6668M3.3335 9.33333H12.6668" stroke="#161616" stroke-opacity="0.6" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </td>
                        <td
                            className={`text-center border-b border-[#80808080] whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}

                        >
                           % change 
                        </td>
                    </tr>
                </thead>
                <tbody className="w-[100%]">
                    {data.map((data, index) => (
                        <tr key={index} className="">
                            <td className="lg:text-left whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] font-semibold text-[#16161699]">
                                {index + 1}. {data.symbol}
                            </td>
                            <td className="lg:text-left whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] font-semibold text-[#16161699]">
                                {data.current_price}
                            </td>
                            <td className={`flex items-center justify-center gap-[0.62rem] whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] font-semibold ${data.percentage_change > 0 ? "text-[#0FB23C]" : "text-[#B20F19]"}`}>
                                {data.percentage_change > 0 ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6664 4.66V7.33333C14.6664 7.46517 14.6273 7.59403 14.5541 7.70364C14.4808 7.81325 14.3767 7.89867 14.2549 7.94912C14.1331 7.99957 13.9991 8.01277 13.8698 7.98706C13.7405 7.96134 13.6217 7.89787 13.5284 7.80467L12.6664 6.94267L9.13778 10.4713C9.01276 10.5963 8.84323 10.6665 8.66645 10.6665C8.48967 10.6665 8.32014 10.5963 8.19512 10.4713L5.99978 8.276L2.47112 11.8047C2.34538 11.9261 2.17698 11.9933 2.00218 11.9918C1.82738 11.9903 1.66018 11.9202 1.53657 11.7965C1.41297 11.6729 1.34285 11.5057 1.34133 11.3309C1.33981 11.1561 1.40701 10.9877 1.52845 10.862L5.52845 6.862C5.65347 6.73702 5.82301 6.66681 5.99978 6.66681C6.17656 6.66681 6.3461 6.73702 6.47112 6.862L8.66645 9.05733L11.7238 6L10.8618 5.138C10.7686 5.04477 10.7051 4.92599 10.6794 4.79669C10.6537 4.66739 10.6669 4.53336 10.7173 4.41156C10.7678 4.28976 10.8532 4.18565 10.9628 4.1124C11.0724 4.03914 11.2013 4.00003 11.3331 4H13.9998L14.0318 4.00067C14.2021 4.00819 14.3629 4.08092 14.4811 4.20378C14.5992 4.32663 14.6656 4.49022 14.6664 4.66067V4.66Z" fill="#0FB23C" />
                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6664 11.34V8.66667C14.6664 8.53483 14.6273 8.40597 14.5541 8.29636C14.4808 8.18675 14.3767 8.10133 14.2549 8.05088C14.1331 8.00043 13.9991 7.98723 13.8698 8.01294C13.7405 8.03866 13.6217 8.10213 13.5284 8.19533L12.6664 9.05733L9.13778 5.52867C9.01276 5.40369 8.84323 5.33348 8.66645 5.33348C8.48967 5.33348 8.32014 5.40369 8.19512 5.52867L5.99978 7.724L2.47112 4.19533C2.34538 4.07389 2.17698 4.0067 2.00218 4.00822C1.82738 4.00974 1.66018 4.07985 1.53657 4.20345C1.41297 4.32706 1.34285 4.49427 1.34133 4.66907C1.33981 4.84386 1.40701 5.01227 1.52845 5.138L5.52845 9.138C5.65347 9.26298 5.82301 9.33319 5.99978 9.33319C6.17656 9.33319 6.3461 9.26298 6.47112 9.138L8.66645 6.94267L11.7238 10L10.8618 10.862C10.7686 10.9552 10.7051 11.074 10.6794 11.2033C10.6537 11.3326 10.6669 11.4666 10.7173 11.5884C10.7678 11.7102 10.8532 11.8143 10.9628 11.8876C11.0724 11.9609 11.2013 12 11.3331 12H13.9998L14.0318 11.9993C14.2021 11.9918 14.3629 11.9191 14.4811 11.7962C14.5992 11.6734 14.6656 11.5098 14.6664 11.3393V11.34Z" fill="#B20F19" />
                                </svg>} {data.percentage_change}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default MostTradedStockTable