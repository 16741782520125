import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';






function MainBarChart({data}) {
    // const data = [
    //     {
    //         name: 'Equities',
    //         amt: 2400,
    //     },
    //     {
    //         name: 'Fixed Income Securities',
    //         amt: 2210,
    //     },
    //     {
    //         name: 'ETFs',
    //         amt: 2290,
    //     },
    //     {
    //         name: 'REITs',
    //         amt: 2000,
    //     },
    //     {
    //         name: 'Money Market Instruments',
    //         amt: 2181,
    //     },
    //     {
    //         name: 'Mutual Funds',
    //         amt: 2500,
    //     },
    // ];
    return (
        <ResponsiveContainer width="100%" aspect={window.innerWidth > 640 ? 1.8 : 1}>
            <BarChart
                // width={500}
                // height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 50,
                }}
                barCategoryGap={window.innerWidth > 640 ? 30 : 5}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis fontSize={"0.625rem"} fontFamily={'Poppins, sans-serif'} dataKey="name" angle={-55} textAnchor='end'/>
                <YAxis fontSize={"0.625rem"} fontFamily={'Poppins, sans-serif'} tickFormatter={(value) => {
                    return `${value} U`
                }} padding={{ top: 50, right: 500}}/>
                <Tooltip formatter={(value) => {
                    console.log(value)
                    return value.toLocaleString()
                }} wrapperStyle={{ backgroundColor: "#0FB23C" }} contentStyle={{ backgroundColor: "#0FB23C", color: "white", textAlign: "center" }} itemStyle={{ backgroundColor: "#0FB23C", color: "white" }} labelStyle={{ backgroundColor: "#0FB23C" }} />
                {/* <Legend /> */}
                <Bar dataKey="amt" name="Amount" fill="#0FB23C" shape={(props) => (
                    <rect
                        {...props}
                        rx={5}
                        ry={5}
                    />
                )} />
                {/* <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} /> */}
            </BarChart>
        </ResponsiveContainer>
    )
}

export default MainBarChart