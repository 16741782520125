import { Dialog } from "@headlessui/react";
import axios from "axios";
import { useState } from "react";

function MyDialog({
  isOpen,
  setIsOpen,
  message,
  transferCode,
  setTransferCode,
  //   customerDataForTransfer,
  handleApprove,
  id,
  type,
  setMessage,
}) {
  const [otp, setOtp] = useState("");

  const payWithOtp = () => {
    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRETE_KEY}`,
      "Content-Type": "application/json",
    };

    const data = JSON.stringify({
      transfer_code: transferCode,
      otp: otp,
    });

    setMessage("Processing OTP, Please Wait...");

    try {
      axios
        .post("https://api.paystack.co/transfer/finalize_transfer", data, {
          headers,
        })
        .then((response) => {
          console.log(response);
          setMessage(response.data.message);
          setTransferCode("");
          handleApprove(id, type);
          //   updateCompleted(customerDataForTransfer);
        })
        .catch((error) => {
          setMessage(error.response.data.message);
        });
    } catch (error) {}
  };

  //   const updateCompleted = async (customerDataForUpdateCompleted) => {
  //     let url = `https://sheetdb.io/api/v1/lx1qt8sgjm0gl/id/${customerDataForUpdateCompleted.id}`;
  //     let body = {
  //       Account_Name: customerDataForUpdateCompleted.Account_Name,
  //       Account_Number: customerDataForUpdateCompleted.Account_Number,
  //       Amount_Naira: customerDataForUpdateCompleted.Amount_Naira,
  //       Approval_Status: customerDataForUpdateCompleted.Approval_Status,
  //       Bank: customerDataForUpdateCompleted.Bank,
  //       Completed: "Yes",
  //       Date: customerDataForUpdateCompleted.Date,
  //       Type: customerDataForUpdateCompleted.Type,
  //     };
  //     fetch(url, {
  //       method: "PATCH",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(body),
  //     })
  //       .then((response) => response.json())
  //       .then((json) => {
  //         // Do something with object
  //         console.log(json.sheet1);
  //       });
  //   };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-4">
          <Dialog.Title className="text-center text-lg font-medium">
            {message}
          </Dialog.Title>
          {transferCode !== "" && (
            <div className="flex items-center gap-2 mt-3">
              <input
                type="text"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
                className="border outline-none border-black rounded-lg px-2 py-1"
              />
              <button
                className="text-white bg-[#359602] text-lg py-0.5 px-2 rounded-lg disabled:bg-gray-500"
                disabled={otp.length < 6}
                onClick={payWithOtp}
              >
                Pay with OTP
              </button>
            </div>
          )}
          <button
            className="text-white bg-[#359602] text-lg py-0.5 px-2 rounded-lg border border-[#359602] mt-5"
            onClick={() => {
              setIsOpen(false);
              window.location.reload();
            }}
          >
            Close
          </button>

          {/* ... */}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default MyDialog;
