/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'
import ReferralManagementTable from '../Tables/ReferralManagementTable';
import ProfilePic from "../../assets/Frame 36951.png"
import AffiliateProgramTable from '../Tables/AffiliateProgramTable';
import AffiliateRewardModal from '../Modals/AffiliateRewardModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import axios from '../../axios';

const referralManagementHeaders = [
  {
    key: "referralID",
    label: "Referral ID",
  },
  {
    key: "referring_user",
    label: "Referring User"
  },
  {
    key: "referred_user",
    label: "Referred User"
  },
  {
    key: "reward",
    label: "Reward",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "date",
    label: "Date",
  },
];

const affiliateProgramHeaders = [
  {
    key: "rank",
    label: "Rank",
  },
  {
    key: "username",
    label: "Username"
  },
  {
    key: "userID",
    label: "User ID"
  },
  {
    key: "referrals",
    label: "Referrals",
  },
  {
    key: "earnings",
    label: "Earnings",
  },
];

function getOrdinalSuffix(number) {
  // Handle special cases for 11, 12, and 13
  if (number % 100 >= 11 && number % 100 <= 13) {
    return "th";
  }

  // For other numbers, determine the ordinal suffix based on the last digit
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}



function Referrals() {
  // const [page, setPage] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [referrals, setReferrals] = useState([])
  const [affiliateData, setAffiliateData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [affilliateProgramPage, setAffiliateProgramPage] = useState(2)
  const [campaignDetails, setCampaignDetails] = useState({
    first_prize: "",
    second_prize: "",
    third_prize: "",
  });
  const [loading, setLoading] = useState(false)
  const [campaignData, setCampaignData] = useState([])
  const [filter, setFilter] = useState("")
  const [gettingRanking, setGettingRanking] = useState(false)
  const [selectedCampaignId, setSelectedCampaignId] = useState(null)
  const [selectedRank, setSelectedRank] = useState("")
  const [rewardId, setRewardId] = useState(null)
  const [awarded, setAwarded] = useState(null)
  let navigate = useNavigate();


  useEffect(() => {
    const fetchReferrals = async () => {
      axios.get("manage-referrals/").then(res => {
        console.log(res)
        setReferrals(res.data)
      }).catch(err => {
        console.log(err)
      })
    }

    // const fetchAffiliateData = async () => {
    //   axios.get("affilate-ranks/").then(res => {
    //     console.log(res)
    //     setAffiliateData(res.data)
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // }

    const fetchCampaignData = async () => {
      axios.get("https://api-hamma-f0bcaabf77ea.herokuapp.com/affilate/campaign/").then(res => {
        console.log(res)
        setCampaignData(res.data)
        // setAffiliateData(res.data)
      }).catch(err => {
        console.log(err)
      })
    }
    fetchCampaignData()
    // fetchAffiliateData()
    fetchReferrals()
  }, [refresh])
  const params = useLocation()

  const CreateCampaign = async () => {
    setLoading(true)
    try {
      axios.post("https://api-hamma-f0bcaabf77ea.herokuapp.com/affilate/campaign/", campaignDetails).then(res => {
        console.log(res)
        setRefresh(!refresh)
        setLoading(false)
        navigate("/referrals?affilliate=main")
        // setAffiliateProgramPage(0)
      }).catch(err => {
        console.log(err)
        // window.location.reload()
      })
    } catch (error) {

    }
  }

  const getCampaignLeaderoard = async (status, id) => {
    setGettingRanking(true)
    if (status === "ongoing") {
      try {
        axios.get("https://api-hamma-f0bcaabf77ea.herokuapp.com/affilate/ranking/").then(res => {
          console.log(res)
          setAffiliateData(res.data)
          navigate("/referrals?affilliate=leaderboard")
        }).catch(err => {
          console.log(err)
        })
      } catch (error) {

      }
    } else {
      try {
        axios.get(`https://api-hamma-f0bcaabf77ea.herokuapp.com/affilate/campaign-summary/${id}/`).then(res => {
          console.log(res)
          setAffiliateData(res.data.sort((a, b) => a.rank - b.rank))
          navigate("/referrals?affilliate=leaderboard")
        }).catch(err => {
          console.log(err)
        })
      } catch (error) {

      }
    }

  }

  const userIdAvailable = params.search.split("=")[1]
  console.log(params.search.split("=")[1])
  return (
    <div className='pb-[2rem]'>
      <div className='lg:px-[2rem]'>
        <div className='flex overflow-x-scroll no-scrollbar border-t border-t-[#D8D8D8] max-w-max'>
          <Link to={`/referrals`}>
            <p className={`px-[1rem] whitespace-nowrap cursor-pointer py-[0.75rem] rounded-bl-[0.75rem] ${!userIdAvailable ? "bg-[#0FB23C] text-white" : "bg-white text-[#16161699]"} font-poppins text-[0.875rem]`}>Referral Management</p>
          </Link>
          <Link to={`/referrals?affilliate=main`}>
            <p className={`px-[1rem] whitespace-nowrap cursor-pointer py-[0.75rem] rounded-br-[0.75rem] ${userIdAvailable ? "bg-[#0FB23C] text-white" : "bg-white text-[#16161699]"} font-poppins text-[0.875rem]`}>Affiliate Program</p>
          </Link>
        </div>
        <div className='px-[1rem] lg:px-0'>

          {!userIdAvailable && <div className='p-[0.62rem] rounded-[0.75rem] bg-[#0FB23C1A] font-poppins mt-[2rem]'>
            <h1 className='flex items-center gap-[0.62rem] font-semibold text-[0.875rem] text-[#018B28]'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0_1652_8242)">
                <path d="M8.6665 5.00016C8.6665 5.17697 8.59627 5.34654 8.47124 5.47157C8.34622 5.59659 8.17665 5.66683 7.99984 5.66683C7.82303 5.66683 7.65346 5.59659 7.52843 5.47157C7.40341 5.34654 7.33317 5.17697 7.33317 5.00016C7.33317 4.82335 7.40341 4.65378 7.52843 4.52876C7.65346 4.40373 7.82303 4.3335 7.99984 4.3335C8.17665 4.3335 8.34622 4.40373 8.47124 4.52876C8.59627 4.65378 8.6665 4.82335 8.6665 5.00016ZM6.6665 7.50016C6.6665 7.36755 6.71918 7.24038 6.81295 7.14661C6.90672 7.05284 7.0339 7.00016 7.1665 7.00016H8.1665C8.29911 7.00016 8.42629 7.05284 8.52006 7.14661C8.61383 7.24038 8.6665 7.36755 8.6665 7.50016V10.3335H9.1665C9.29911 10.3335 9.42629 10.3862 9.52006 10.4799C9.61383 10.5737 9.6665 10.7009 9.6665 10.8335C9.6665 10.9661 9.61383 11.0933 9.52006 11.187C9.42629 11.2808 9.29911 11.3335 9.1665 11.3335H7.1665C7.0339 11.3335 6.90672 11.2808 6.81295 11.187C6.71918 11.0933 6.6665 10.9661 6.6665 10.8335C6.6665 10.7009 6.71918 10.5737 6.81295 10.4799C6.90672 10.3862 7.0339 10.3335 7.1665 10.3335H7.6665V8.00016H7.1665C7.0339 8.00016 6.90672 7.94748 6.81295 7.85372C6.71918 7.75995 6.6665 7.63277 6.6665 7.50016Z" fill="#018B28" />
                <path d="M7.99984 0.666504C12.0498 0.666504 15.3332 3.94984 15.3332 7.99984C15.3332 12.0498 12.0498 15.3332 7.99984 15.3332C3.94984 15.3332 0.666504 12.0498 0.666504 7.99984C0.666504 3.94984 3.94984 0.666504 7.99984 0.666504ZM1.6665 7.99984C1.6665 9.67954 2.33376 11.2905 3.52149 12.4782C4.70922 13.6659 6.32013 14.3332 7.99984 14.3332C9.67954 14.3332 11.2905 13.6659 12.4782 12.4782C13.6659 11.2905 14.3332 9.67954 14.3332 7.99984C14.3332 6.32013 13.6659 4.70922 12.4782 3.52149C11.2905 2.33376 9.67954 1.6665 7.99984 1.6665C6.32013 1.6665 4.70922 2.33376 3.52149 3.52149C2.33376 4.70922 1.6665 6.32013 1.6665 7.99984Z" fill="#018B28" />
              </g>
              <defs>
                <clipPath id="clip0_1652_8242">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>INSTRUCTIONS</h1>
            <p className='text-[0.875rem] text-[#161616] mt-[0.62rem]'>To sort any of the tabs click on the tab heading, and click again to reverse the data. View details of referrals by clicking on Referral ID. Select referred user by clicking their name.</p>
          </div>}
          {userIdAvailable === "leaderboard" && <div className='p-[0.62rem] rounded-[0.75rem] bg-[#0FB23C1A] font-poppins mt-[2rem]'>
            <h1 className='flex items-center gap-[0.62rem] font-semibold text-[0.875rem] text-[#018B28]'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0_1652_8242)">
                <path d="M8.6665 5.00016C8.6665 5.17697 8.59627 5.34654 8.47124 5.47157C8.34622 5.59659 8.17665 5.66683 7.99984 5.66683C7.82303 5.66683 7.65346 5.59659 7.52843 5.47157C7.40341 5.34654 7.33317 5.17697 7.33317 5.00016C7.33317 4.82335 7.40341 4.65378 7.52843 4.52876C7.65346 4.40373 7.82303 4.3335 7.99984 4.3335C8.17665 4.3335 8.34622 4.40373 8.47124 4.52876C8.59627 4.65378 8.6665 4.82335 8.6665 5.00016ZM6.6665 7.50016C6.6665 7.36755 6.71918 7.24038 6.81295 7.14661C6.90672 7.05284 7.0339 7.00016 7.1665 7.00016H8.1665C8.29911 7.00016 8.42629 7.05284 8.52006 7.14661C8.61383 7.24038 8.6665 7.36755 8.6665 7.50016V10.3335H9.1665C9.29911 10.3335 9.42629 10.3862 9.52006 10.4799C9.61383 10.5737 9.6665 10.7009 9.6665 10.8335C9.6665 10.9661 9.61383 11.0933 9.52006 11.187C9.42629 11.2808 9.29911 11.3335 9.1665 11.3335H7.1665C7.0339 11.3335 6.90672 11.2808 6.81295 11.187C6.71918 11.0933 6.6665 10.9661 6.6665 10.8335C6.6665 10.7009 6.71918 10.5737 6.81295 10.4799C6.90672 10.3862 7.0339 10.3335 7.1665 10.3335H7.6665V8.00016H7.1665C7.0339 8.00016 6.90672 7.94748 6.81295 7.85372C6.71918 7.75995 6.6665 7.63277 6.6665 7.50016Z" fill="#018B28" />
                <path d="M7.99984 0.666504C12.0498 0.666504 15.3332 3.94984 15.3332 7.99984C15.3332 12.0498 12.0498 15.3332 7.99984 15.3332C3.94984 15.3332 0.666504 12.0498 0.666504 7.99984C0.666504 3.94984 3.94984 0.666504 7.99984 0.666504ZM1.6665 7.99984C1.6665 9.67954 2.33376 11.2905 3.52149 12.4782C4.70922 13.6659 6.32013 14.3332 7.99984 14.3332C9.67954 14.3332 11.2905 13.6659 12.4782 12.4782C13.6659 11.2905 14.3332 9.67954 14.3332 7.99984C14.3332 6.32013 13.6659 4.70922 12.4782 3.52149C11.2905 2.33376 9.67954 1.6665 7.99984 1.6665C6.32013 1.6665 4.70922 2.33376 3.52149 3.52149C2.33376 4.70922 1.6665 6.32013 1.6665 7.99984Z" fill="#018B28" />
              </g>
              <defs>
                <clipPath id="clip0_1652_8242">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>INSTRUCTIONS</h1>
            <p className='text-[0.875rem] text-[#161616] mt-[0.62rem]'>To sort any of the tabs click on the tab heading, and click again to reverse the data. View details of users by clicking on Username. Click on View Rewards to see rewards.</p>
          </div>}
          {userIdAvailable &&
            <>
              {userIdAvailable === "main" && <div className='bg-white rounded-[12px] p-[1rem] mt-[2rem] flex flex-col'>
                <div className='flex gap-[1rem] ml-auto'>
                  <div className='flex lg:flex-row flex-col mt-5 lg:mt-0 items-center gap-[1rem]'>
                    <Menu>
                      {({ open }) => (
                        <>
                          <Menu.Button onClick={() => { }}>
                            <button className={`${open ? "border border-[#0FB23C]" : "border border-[#F1F1F1]"} flex items-center gap-[0.25rem] bg-[#F1F1F1] py-[0.5rem] px-[0.75rem] rounded-[0.75rem] font-poppins text-[0.875rem] text-[#161616]`}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <g clip-path="url(#clip0_1652_8257)">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7998 4.5C3.7998 4.10218 3.95784 3.72064 4.23914 3.43934C4.52045 3.15804 4.90198 3 5.2998 3H20.2998C20.6976 3 21.0792 3.15804 21.3605 3.43934C21.6418 3.72064 21.7998 4.10218 21.7998 4.5V6.586C21.7997 7.11639 21.5889 7.62501 21.2138 8L15.7998 13.414V20.838C15.7998 21.0255 15.7519 21.2099 15.6606 21.3737C15.5693 21.5375 15.4377 21.6753 15.2781 21.7739C15.1186 21.8724 14.9366 21.9286 14.7492 21.9371C14.5619 21.9455 14.3755 21.9059 14.2078 21.822L10.4908 19.964C10.2832 19.8602 10.1086 19.7006 9.98651 19.5031C9.86446 19.3057 9.79981 19.0781 9.7998 18.846V13.414L4.3858 8C4.01071 7.62501 3.79992 7.11639 3.7998 6.586V4.5ZM5.7998 5V6.586L11.3598 12.146C11.4993 12.2853 11.6099 12.4507 11.6854 12.6327C11.7609 12.8148 11.7998 13.0099 11.7998 13.207V18.382L13.7998 19.382V13.207C13.7998 12.809 13.9578 12.427 14.2398 12.147L19.7998 6.585V5H5.7998Z" fill="#161616" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1652_8257">
                                    <rect width="24" height="24" fill="white" transform="translate(0.799805)" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>Filters</p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                <g clip-path="url(#clip0_1652_8262)">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.27104 10.4712C9.14602 10.5962 8.97648 10.6664 8.79971 10.6664C8.62293 10.6664 8.45339 10.5962 8.32837 10.4712L4.55704 6.6999C4.49337 6.63841 4.44258 6.56484 4.40764 6.48351C4.3727 6.40217 4.35431 6.31469 4.35354 6.22617C4.35277 6.13765 4.36964 6.04986 4.40316 5.96793C4.43668 5.886 4.48618 5.81157 4.54878 5.74897C4.61137 5.68638 4.68581 5.63688 4.76774 5.60336C4.84967 5.56983 4.93746 5.55297 5.02598 5.55374C5.11449 5.55451 5.20197 5.5729 5.28331 5.60784C5.36465 5.64277 5.43821 5.69356 5.49971 5.75724L8.79971 9.05724L12.0997 5.75724C12.2254 5.6358 12.3938 5.5686 12.5686 5.57012C12.7434 5.57164 12.9106 5.64175 13.0343 5.76536C13.1579 5.88896 13.228 6.05617 13.2295 6.23097C13.231 6.40577 13.1638 6.57417 13.0424 6.6999L9.27104 10.4712Z" fill="#161616" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1652_8262">
                                    <rect width="16" height="16" fill="white" transform="translate(0.799805)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </button>
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute shadow-[0_4px_16px_2px_rgba(1,1,1,0.15)] lg:w-[11.2rem] w-[80%] z-10 lg:mt-[8rem] mt-[3rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] bg-[#F1F1F1] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault()
                                      setFilter("closed")
                                      // console.log("clicked")
                                    }}
                                    className={`group gap-[1rem] justify-start flex w-full items-center font-poppins rounded-t-[0.75rem] py-[0.5rem] px-[0.75rem] text-[0.875rem]`}
                                  >
                                    <input type="radio" checked={filter === "closed"} className='scale-125 accent-[#0FB23C] acc' />
                                    <p className='text-[#16161699]'>Status: <span className='text-[#161616]'>Closed</span></p>
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault()
                                      setFilter("ongoing")
                                      // console.log("clicked")
                                    }}
                                    className={`group gap-[1rem] justify-start flex w-full items-center font-poppins rounded-b-[0.75rem] py-[0.5rem] px-[0.75rem] text-[0.875rem]`}
                                  >
                                    <input type="radio" checked={filter === "ongoing"} className='scale-125 accent-[#0FB23C]' />
                                    <p className='text-[#16161699]'>Status: <span className='text-[#161616]'>Ongoing</span></p>
                                  </button>
                                )}
                              </Menu.Item>

                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                  <Link to={`/referrals?affilliate=create`}>
                    <button className='bg-[#0FB23C] rounded-[12px] px-[2rem] py-[12px] text-white font-poppins font-semibold'>Create New</button>
                  </Link>
                </div>
                <div className='flex gap-[2rem] flex-wrap'>
                  {campaignData.filter(da => {
                    if (filter === "") {
                      return da
                    } else {
                      return da.status === filter
                    }
                  }).map(dat => (
                    <div key={dat.id} className='flex flex-col gap-[1rem] shadow-lg p-[24px] rounded-[12px]'>
                      <div className='flex flex-col gap-[1rem] w-[240px]'>
                        <p className={`${dat.status === "ongoing" ? "bg-[#E7A6001A] text-[#E7A600]" : "bg-[#0FB23C1A] text-[#0FB23C]"} max-w-max font-poppins rounded-full px-[1rem] py-[2px]`}>{dat.status}</p>
                        <h1 className='font-poppins font-semibold text-[1.25rem]'>{dat.quarter}{getOrdinalSuffix(dat.quarter)} Qtr/{dat.year}</h1>
                        <p onClick={() => {
                          setSelectedCampaignId(dat.id)
                          getCampaignLeaderoard(dat.status, dat.id)
                        }} className='font-poppins text-[0.875rem] cursor-pointer'>{selectedCampaignId === dat.id && gettingRanking === true ? "Please wait..." : "View leaderboard"}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>}


              {userIdAvailable === "leaderboard" && <div className='mt-[2rem] flex lg:flex-row flex-col gap-[1.5rem] items-center justify-between'>
                {affiliateData[1] && <div className='bg-white shadow-[0_2px_16px_0_rgba(128,128,128,0.18)] border border-white rounded-[0.75rem] flex flex-col items-center gap-[1rem] p-[1rem]'>
                  {/* <svg className='ml-auto' xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <g clip-path="url(#clip0_1991_4397)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.19922 14C6.19922 13.4696 6.40993 12.9609 6.78501 12.5858C7.16008 12.2107 7.66879 12 8.19922 12C8.72965 12 9.23836 12.2107 9.61343 12.5858C9.98851 12.9609 10.1992 13.4696 10.1992 14C10.1992 14.5304 9.98851 15.0391 9.61343 15.4142C9.23836 15.7893 8.72965 16 8.19922 16C7.66879 16 7.16008 15.7893 6.78501 15.4142C6.40993 15.0391 6.19922 14.5304 6.19922 14ZM6.19922 2C6.19922 1.46957 6.40993 0.960859 6.78501 0.585786C7.16008 0.210713 7.66879 0 8.19922 0C8.72965 0 9.23836 0.210713 9.61343 0.585786C9.98851 0.960859 10.1992 1.46957 10.1992 2C10.1992 2.53043 9.98851 3.03914 9.61343 3.41421C9.23836 3.78929 8.72965 4 8.19922 4C7.66879 4 7.16008 3.78929 6.78501 3.41421C6.40993 3.03914 6.19922 2.53043 6.19922 2ZM6.19922 7.8192C6.19922 7.28877 6.40993 6.78006 6.78501 6.40499C7.16008 6.02991 7.66879 5.8192 8.19922 5.8192C8.72965 5.8192 9.23836 6.02991 9.61343 6.40499C9.98851 6.78006 10.1992 7.28877 10.1992 7.8192C10.1992 8.34963 9.98851 8.85834 9.61343 9.23341C9.23836 9.60849 8.72965 9.8192 8.19922 9.8192C7.66879 9.8192 7.16008 9.60849 6.78501 9.23341C6.40993 8.85834 6.19922 8.34963 6.19922 7.8192Z" fill="#161616" />
                </g>
                <defs>
                  <clipPath id="clip0_1991_4397">
                    <rect width="16" height="16" fill="white" transform="matrix(0 -1 1 0 0.199219 16)" />
                  </clipPath>
                </defs>
              </svg> */}
                  <img src={affiliateData[1].image_url} alt="" className='w-[6.625rem] h-[6.625rem]' />
                  <h1 className='font-poppins text-[1.5rem] font-medium text-[#161616]'>{affiliateData[1]?.username}</h1>
                  <p className='font-poppins text-[0.875rem] text-[#161616] bg-[#F1F1F1] px-[1rem] rounded-full py-[0.25rem]'>User ID: {affiliateData[1]?.user_id || affiliateData[1]?.user}</p>
                  <div className='flex items-center gap-[1.5rem]'>
                    <p className='font-poppins text-[0.875rem] text-[#16161699]'>Earnings: <span className='text-[#161616] text-[1rem] font-semibold'>N{affiliateData[1]?.brokerage_commission || affiliateData[1]?.earnings}</span></p>
                    <p className='font-poppins text-[0.875rem] text-[#16161699]'>Referrals: <span className='text-[#161616] text-[1rem] font-semibold'>{affiliateData[1]?.active_referees || affiliateData[1]?.referrals}</span></p>
                    <p className='font-poppins text-[0.875rem] text-[#16161699]'>Rank: <span className='text-[#161616] text-[1rem] font-semibold'>#2</span></p>
                  </div>
                  <button onClick={() => {
                    if (affiliateData[1].campaign) {
                      setSelectedRank("second")
                      setRewardId(affiliateData[1].id)
                      setAwarded(affiliateData[1].awarded)
                      setIsOpen(!isOpen)
                    }
                  }} className='font poppins text-[0.875rem] text-[#161616]'>View Rewards</button>
                </div>}
                {affiliateData[0] && <div className='shadow-[0_2px_16px_0_rgba(128,128,128,0.18)] border bg-[#0FB23C1A] border-[#0FB23C] rounded-[0.75rem] flex flex-col items-center gap-[1rem] p-[1rem]'>
                  {/* <svg className='ml-auto' xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <g clip-path="url(#clip0_1991_4397)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.19922 14C6.19922 13.4696 6.40993 12.9609 6.78501 12.5858C7.16008 12.2107 7.66879 12 8.19922 12C8.72965 12 9.23836 12.2107 9.61343 12.5858C9.98851 12.9609 10.1992 13.4696 10.1992 14C10.1992 14.5304 9.98851 15.0391 9.61343 15.4142C9.23836 15.7893 8.72965 16 8.19922 16C7.66879 16 7.16008 15.7893 6.78501 15.4142C6.40993 15.0391 6.19922 14.5304 6.19922 14ZM6.19922 2C6.19922 1.46957 6.40993 0.960859 6.78501 0.585786C7.16008 0.210713 7.66879 0 8.19922 0C8.72965 0 9.23836 0.210713 9.61343 0.585786C9.98851 0.960859 10.1992 1.46957 10.1992 2C10.1992 2.53043 9.98851 3.03914 9.61343 3.41421C9.23836 3.78929 8.72965 4 8.19922 4C7.66879 4 7.16008 3.78929 6.78501 3.41421C6.40993 3.03914 6.19922 2.53043 6.19922 2ZM6.19922 7.8192C6.19922 7.28877 6.40993 6.78006 6.78501 6.40499C7.16008 6.02991 7.66879 5.8192 8.19922 5.8192C8.72965 5.8192 9.23836 6.02991 9.61343 6.40499C9.98851 6.78006 10.1992 7.28877 10.1992 7.8192C10.1992 8.34963 9.98851 8.85834 9.61343 9.23341C9.23836 9.60849 8.72965 9.8192 8.19922 9.8192C7.66879 9.8192 7.16008 9.60849 6.78501 9.23341C6.40993 8.85834 6.19922 8.34963 6.19922 7.8192Z" fill="#161616" />
                </g>
                <defs>
                  <clipPath id="clip0_1991_4397">
                    <rect width="16" height="16" fill="white" transform="matrix(0 -1 1 0 0.199219 16)" />
                  </clipPath>
                </defs>
              </svg> */}
                  <img src={affiliateData[0].image_url} alt="" className='w-[6.625rem] h-[6.625rem]' />
                  <h1 className='font-poppins text-[1.5rem] font-medium text-[#161616]'>{affiliateData[0]?.username}</h1>
                  <p className='font-poppins bg-[#0FB23C] text-white text-[0.875rem] px-[1rem] rounded-full py-[0.25rem]'>User ID: {affiliateData[0]?.user_id || affiliateData[0]?.user}</p>
                  <div className='flex items-center gap-[1.5rem]'>
                    <p className='font-poppins text-[0.875rem] text-[#16161699]'>Earnings: <span className='text-[#161616] text-[1rem] font-semibold'>N{affiliateData[0]?.brokerage_commission || affiliateData[0]?.earnings}</span></p>
                    <p className='font-poppins text-[0.875rem] text-[#16161699]'>Referrals: <span className='text-[#161616] text-[1rem] font-semibold'>{affiliateData[0]?.active_referees || affiliateData[0]?.referrals}</span></p>
                    <p className='font-poppins text-[0.875rem] text-[#16161699]'>Rank: <span className='text-[#161616] text-[1rem] font-semibold'>#1</span></p>
                  </div>
                  <button onClick={() => {
                    if (affiliateData[0].campaign) {
                      setSelectedRank("first")
                      setRewardId(affiliateData[0].id)
                      setAwarded(affiliateData[0].awarded)
                      setIsOpen(!isOpen)
                    }
                  }} className='font poppins text-[0.875rem] text-[#161616]'>View Rewards</button>
                </div>}
                {affiliateData[2] && <div className='bg-white shadow-[0_2px_16px_0_rgba(128,128,128,0.18)] border border-white rounded-[0.75rem] flex flex-col items-center gap-[1rem] p-[1rem]'>
                  {/* <svg className='ml-auto' xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <g clip-path="url(#clip0_1991_4397)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.19922 14C6.19922 13.4696 6.40993 12.9609 6.78501 12.5858C7.16008 12.2107 7.66879 12 8.19922 12C8.72965 12 9.23836 12.2107 9.61343 12.5858C9.98851 12.9609 10.1992 13.4696 10.1992 14C10.1992 14.5304 9.98851 15.0391 9.61343 15.4142C9.23836 15.7893 8.72965 16 8.19922 16C7.66879 16 7.16008 15.7893 6.78501 15.4142C6.40993 15.0391 6.19922 14.5304 6.19922 14ZM6.19922 2C6.19922 1.46957 6.40993 0.960859 6.78501 0.585786C7.16008 0.210713 7.66879 0 8.19922 0C8.72965 0 9.23836 0.210713 9.61343 0.585786C9.98851 0.960859 10.1992 1.46957 10.1992 2C10.1992 2.53043 9.98851 3.03914 9.61343 3.41421C9.23836 3.78929 8.72965 4 8.19922 4C7.66879 4 7.16008 3.78929 6.78501 3.41421C6.40993 3.03914 6.19922 2.53043 6.19922 2ZM6.19922 7.8192C6.19922 7.28877 6.40993 6.78006 6.78501 6.40499C7.16008 6.02991 7.66879 5.8192 8.19922 5.8192C8.72965 5.8192 9.23836 6.02991 9.61343 6.40499C9.98851 6.78006 10.1992 7.28877 10.1992 7.8192C10.1992 8.34963 9.98851 8.85834 9.61343 9.23341C9.23836 9.60849 8.72965 9.8192 8.19922 9.8192C7.66879 9.8192 7.16008 9.60849 6.78501 9.23341C6.40993 8.85834 6.19922 8.34963 6.19922 7.8192Z" fill="#161616" />
                </g>
                <defs>
                  <clipPath id="clip0_1991_4397">
                    <rect width="16" height="16" fill="white" transform="matrix(0 -1 1 0 0.199219 16)" />
                  </clipPath>
                </defs>
              </svg> */}
                  <img src={affiliateData[2].image_url} alt="" className='w-[6.625rem] h-[6.625rem]' />
                  <h1 className='font-poppins text-[1.5rem] font-medium text-[#161616]'>{affiliateData[2]?.username}</h1>
                  <p className='font-poppins text-[0.875rem] text-[#161616] bg-[#F1F1F1] px-[1rem] rounded-full py-[0.25rem]'>User ID: {affiliateData[2]?.user_id || affiliateData[0]?.user}</p>
                  <div className='flex items-center gap-[1.5rem]'>
                    <p className='font-poppins text-[0.875rem] text-[#16161699]'>Earnings: <span className='text-[#161616] text-[1rem] font-semibold'>N{affiliateData[2]?.brokerage_commission || affiliateData[0]?.earnings}</span></p>
                    <p className='font-poppins text-[0.875rem] text-[#16161699]'>Referrals: <span className='text-[#161616] text-[1rem] font-semibold'>{affiliateData[2]?.active_referees || affiliateData[0]?.referrals}</span></p>
                    <p className='font-poppins text-[0.875rem] text-[#16161699]'>Rank: <span className='text-[#161616] text-[1rem] font-semibold'>#3</span></p>
                  </div>
                  <button onClick={() => {
                    if (affiliateData[2].campaign) {
                      setRewardId(affiliateData[2].id)
                      setAwarded(affiliateData[2].awarded)
                      setSelectedRank("third")
                      setIsOpen(!isOpen)
                    }
                  }} className='font poppins text-[0.875rem] text-[#161616]'>View Rewards</button>
                </div>}
              </div>}

              {userIdAvailable === "create" && <div className='bg-white rounded-[12px] mt-[2rem] flex flex-col gap-[1rem] py-[1rem] items-center justify-center'>
                <h1 className='font-poppins font-semibold'>Prizes</h1>
                <div className='flex flex-col lg:w-[40%]'>
                  <label className='font-poppins text-[12px] mb-[4px]' htmlFor="">1st Place</label>
                  <input onChange={e => setCampaignDetails({ ...campaignDetails, first_prize: e.target.value })} type="number" className='bg-[#F1F1F1] p-[1rem] rounded-[12px]' />
                </div>
                <div className='flex flex-col lg:w-[40%]'>
                  <label className='font-poppins text-[12px] mb-[4px]' htmlFor="">2nd Place</label>
                  <input onChange={e => setCampaignDetails({ ...campaignDetails, second_prize: e.target.value })} type="number" className='bg-[#F1F1F1] p-[1rem] rounded-[12px]' />
                </div>
                <div className='flex flex-col lg:w-[40%]'>
                  <label className='font-poppins text-[12px] mb-[4px]' htmlFor="">3rd Place</label>
                  <input onChange={e => setCampaignDetails({ ...campaignDetails, third_prize: e.target.value })} type="number" className='bg-[#F1F1F1] p-[1rem] rounded-[12px]' />
                </div>
                <button onClick={CreateCampaign} className='bg-[#0FB23C] rounded-[12px] px-[2rem] py-[12px] text-white font-poppins font-semibold'>{loading ? "Please wait..." : "Create Affiliate Program"}</button>
              </div>}
            </>}

          {!userIdAvailable && <ReferralManagementTable head={referralManagementHeaders} body={referrals} refresh={refresh} setRefresh={setRefresh} />}
          {userIdAvailable === "leaderboard" && <AffiliateProgramTable head={affiliateProgramHeaders} body={affiliateData} />}
        </div>
      </div>
      <AffiliateRewardModal awarded={awarded} id={rewardId} rank={selectedRank} data={campaignData.filter(dat => dat.id === selectedCampaignId).map(data => ({ first: data.first_prize, second: data.second_prize, third: data.third_prize }))} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  )
}

export default Referrals